import { defineStore } from 'pinia'
import { reactive, ref} from 'vue';
// import {useClinetStore} from, ''
import axios from "axios";
// import Buffer from 'buffer'
// import ref from '@ref'

export const useBranchTableStore = defineStore('branchTable', () => {

    const branches = reactive({});
    const order = reactive();
    const company_filter= ref("");
    const franchise_filter= ref(null);
    const is_payed = ref(null)

    const skip= ref(0);
    const take= ref(1000);

    const companies = reactive([]);
    const franchises = ref([]);

    const getCompany = async () => {
      let data = JSON.stringify({
        query: `
        query selectCompany(
          $company:String,
          $franchise:String,
          $block:Boolean,
        ) {
          CompanySelect(name:$company,blocked:$block,franchise:$franchise){
            id,
            name
          }
        }
        `,
        variables: {
          company:company_filter.value,
          franchise_filter:franchise_filter.value,
          block:is_payed.value 
        }
      });
      
      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'https://test-api.entersight.ru/graphql',
        headers: { 
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer ' + localStorage.getItem("admin_token")
        },
        data : data
      };
      
      companies.value = (await axios.request(config)).data.data.selectCompany.companies;

    }

    const getFranchise = async () => {
      let data = JSON.stringify({
        query: `
        query franchise{
          FranchiseSelect{
            franchise
          }
        }
        `,
        variables: {}
      });
      
      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'https://test-api.entersight.ru/graphql',
        headers: { 
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjoiZGRhOGMxM2ItYTAxNi00NDg1LThlZWItNmYxMTA1MWZjOGFlIiwiZW52IjoicHJvZCIsImlhdCI6MTY5NzQ0MzI0NSwiZXhwIjoxNjk5MjQzMjQ1LCJzdWIiOiJkZGE4YzEzYi1hMDE2LTQ0ODUtOGVlYi02ZjExMDUxZmM4YWUifQ.LIwfWXr1Fozax54xaKaiVeKzLjj1mtmVy44IeprrB6Q'
        },
        data : data
      };
      
      franchises.value = (await axios.request(config)).data.data.FranchiseSelect.franchise;
    }
   
    const AdmBRanches = async () => {
        let data = JSON.stringify({
            query: `query Adad(
                $company:String,
                $franchise:String,
                $skip:Int,
                $take:Int,
                $is_payed:Boolean
              ){
                  AdmBranches(input:{company:$company,
                    franchise:$franchise,
                  skip:$skip,
                  is_payed:$is_payed
                  take:$take}){
                  list{
                    id,
                    branch
                    company
                    address
                    franchise
                    subscribe
                    cancel_date
                    is_payed
                  }
                }
              }`,
            variables: {
              company:company_filter.value,
              order:order,
              is_payed:is_payed.value,
              franchise:franchise_filter.value,
              skip:skip.value,
              take:take.value,
            }
          });
          
          let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'https://test-api.entersight.ru/graphql',
            headers: { 
              'Content-Type': 'application/json', 
              'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjoiZGRhOGMxM2ItYTAxNi00NDg1LThlZWItNmYxMTA1MWZjOGFlIiwiZW52IjoicHJvZCIsImlhdCI6MTY5NzQ0MzI0NSwiZXhwIjoxNjk5MjQzMjQ1LCJzdWIiOiJkZGE4YzEzYi1hMDE2LTQ0ODUtOGVlYi02ZjExMDUxZmM4YWUifQ.LIwfWXr1Fozax54xaKaiVeKzLjj1mtmVy44IeprrB6Q'
            },
            data : data
          };
          
          const rez = await axios.request(config)

          const newList = [];
          branches.value = {list:[],total:rez.data.data.AdmBranches.total};
          rez.data.data.AdmBranches.list?.forEach((element,index) => {
            branches.value.list.push({...element,number:index});
          });
          branches.value.list.map( (elem) => {
            elem.subscribe = { cancel_date: new Date (elem.subscribe?.cancel_date || ""),id: elem.subscribe?.id || "", date:""};
            if (elem.subscribe?.cancel_date) {
                const date = new Date(elem.cancel_date);
                let mouth =  (date.getMonth()+1);
                if (mouth < 10) {
                    mouth = "0" + mouth.toString();
                }
                let day =  (date.getDate()+1);
                if (day < 10) {
                    day = "0" + day.toString();
                }
                elem.subscribe.date = date.getFullYear() + "." + mouth + "." + day;
            } else {
                const dateNow = new Date();
                let mouth =  (dateNow.getMonth()+1);
                if (mouth < 10) {
                    mouth = "0" + mouth.toString();
                }
                let day =  (dateNow.getMonth()+1);
                if (day < 10) {
                    day = "0" + day.toString();
                }
                elem.subscribe.date = dateNow.getFullYear() + "." + mouth + "." + day;
            }
            // console.log(elem.subscribe.date);

          })
          branches.value.list.forEach((elem) => {
            if (elem?.franchise === franchise_filter.value) {
              // console.log(elem)  
              newList.push(elem);
            } else if (franchise_filter.value === null) {
              newList.push(elem);
            }

          })
          branches.value.list = newList;
          return rez;
    };

    const chengeDate = async (id,date) => {
      console.log(date)
      console.log(new Date(date));
        let data = JSON.stringify({
            query: ` mutation Ad($date:Date,$branch:ID){
              UpdateSubscription(input:{cancel_date:$date,branch:$branch}){
                id
                cancel_date
              }
            }`,
            variables: {
                branch:id,
                date:new Date(date)
            }
          });
          
          let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'https://test-api.entersight.ru/graphql',
            headers: { 
              'Content-Type': 'application/json', 
              'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjoiZGRhOGMxM2ItYTAxNi00NDg1LThlZWItNmYxMTA1MWZjOGFlIiwiZW52IjoicHJvZCIsImlhdCI6MTY5NzQ0MzI0NSwiZXhwIjoxNjk5MjQzMjQ1LCJzdWIiOiJkZGE4YzEzYi1hMDE2LTQ0ODUtOGVlYi02ZjExMDUxZmM4YWUifQ.LIwfWXr1Fozax54xaKaiVeKzLjj1mtmVy44IeprrB6Q'
            },
            data : data
          };


          
          const rez = await axios.request(config);
          branches.value.list.map(elem => { if (elem.id === id) elem.cancel_date =  rez.data?.data.UpdateSubscription.cancel_date})
    }

    // const ConvertDate = (date,type) => {
    //   let dateNow;
    //   if (type) {
    //     dateNow = new Date(date);
    //   } else {
    //     dateNow = new Date();
    //   }
    //   let mouth =  (dateNow.getMonth()+1);
    //   if (mouth < 10) {
    //     mouth = "0" + mouth.toString();
    //   }
    //   let day =  (dateNow.getMonth()+1);
    //   if (day < 10) {
    //     day = "0" + day.toString();
    //   }
    //   return dateNow.getFullYear() + "." + mouth + "." + day;
    // }

    
    const chengeFranchese = async (id,text) => {
      console.log(text)
        let data = JSON.stringify({
            query: ` daw(
              $branch:String!,
              $franchise:String
            ){
              FranchiseUpdate(branch:"$branch",franchise:$franchise) {
                status
              }
          }`,
            variables: {
              branch:id,
              franchise:text
            }
          });
          
          let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'https://test-api.entersight.ru/graphql',
            headers: { 
              'Content-Type': 'application/json', 
              'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjoiZGRhOGMxM2ItYTAxNi00NDg1LThlZWItNmYxMTA1MWZjOGFlIiwiZW52IjoicHJvZCIsImlhdCI6MTY5NzQ0MzI0NSwiZXhwIjoxNjk5MjQzMjQ1LCJzdWIiOiJkZGE4YzEzYi1hMDE2LTQ0ODUtOGVlYi02ZjExMDUxZmM4YWUifQ.LIwfWXr1Fozax54xaKaiVeKzLjj1mtmVy44IeprrB6Q'
            },
            data : data
          };        
          const rez = await axios.request(config);
          branches.value.list.map(elem => { if (elem.id === id) elem.cancel_date =  rez.data?.data.UpdateSubscription.cancel_date})
    }
    
    return{
        branches,
        AdmBRanches,
        chengeDate,
        chengeFranchese,
        companies,
        franchises,
        getCompany,
        getFranchise,
        company_filter,
        franchise_filter,
        is_payed,
    };

})