<script setup>
    import { useAgentsLogsTableStore} from './store';
    import { onMounted } from 'vue'
    import { storeToRefs } from 'pinia';
    import '@vuepic/vue-datepicker/dist/main.css';
    import eSelectBox from '../../components/vue3-ecomponents/select_box.vue'

    const branchTableStore = useAgentsLogsTableStore();



    const {
        agents,
        logs,
        agent_filter
    } = storeToRefs(branchTableStore);


    onMounted(() => {
        branchTableStore.getAgents();
    })


</script>

<template>
    <h2 style="
    position: absolute;
    left: 272px;
    ">Таблица магазинов</h2>
    <main>
        
            
    <!-- //бошка -->
    <section  style = '
    margin-left: 262px; 
    position: relative;
    top: 100px;
    left: 0px;
    display: flex;
    flex-direction: row;
    '>
        <article>
            <a>Агент</a>
            <eSelectBox
                @change="(company_text) => {
                    agent_filter.value = company_text;
                }"
                @input=" (company_text) => {
                    agent_filter.value = company_text;
                }"
                :value="agent_filter"
                :input="true"
                :width="200"
                style="z-index: 6;"
                :variants="agents">
            </eSelectBox>
        </article>
    </section>
    <section style = '
    margin-left: 262px;
    '>

        <section style = '
        '>
           
            <section
            style="
                overflow-y: scroll;
                height: 700px;
                width: calc(100% - 0px);
                border-radius: 6px;
                margin-top: 118px;
                z-index: 4;
                border: 1px solid var(--gray-gray-150, #E1E1E1);
                background: #FFF;
            "> <section 
            id = 'head' 
            style="
                position: sticky;
                z-index: 4;
                display: grid;
                top: 0px;
                background: #FFF;
                height: 48px;
                width: calc(100% - 75px);
                /* align-content: ; */
                /* padding-left: 5px; */
                grid-template-columns: 2.96666% 30.36666% 16.66666% 16.66666% 21.66666% 16.66666%"
            >
                <article 
                style = '
                border-left: 0px;'>
                №
                </article>
                <article>
                    Сообщение
                </article>
                <article>
                    Версия
                </article>
                <article>
                    Уровень
                </article>
                <article>
                    Дата
                </article>
                <article>
                    Имя
                </article>
                
            </section>
                <section
                
                style="
                display: grid;
                text-align: center;
                /* align-items: center; */
                width: calc(100% - 85px);
                min-height: 48px;
                grid-template-columns: 2.96666% 30.36666% 16.66666% 16.66666% 21.66666% 16.66666%"
                v-for="log in logs.value?.list" :key="log.id">
                <article class = 'grid_cell'>
                    {{ log?.number }}
                </article>
                <article class = 'grid_cell'>
                    {{ log?.message }}
                </article>
                <article class = 'grid_cell'>
                    {{ log?.version }}
                </article>
                <article class = 'grid_cell' >
                    {{ log?.level }}
                </article>
                <article class = 'grid_cell'>
                    <input :value="log?.date"/>
                </article>
                <article class = 'grid_cell'>
                    {{ log?.name }}
                </article>
                </section>
                <footer
                style="
                    height: 42px;
                    width: calc(100% - 290px);
                    border-radius: 6px;
                    background: #FFF;
                    position: absolute;
                    z-index: 4;
                    top: 880px;
                    left: 270px;
                    border-radius: 6px;
                    border: 1px solid var(--gray-gray-150, #E1E1E1);
                    background: #FFF;
                    padding: 6px;
                ">
                <a>Колличество:{{logs.value?.total || logs.value?.list?.length}}</a>
                </footer>
            </section>
            
            <!-- grid -->
        </section>
    </section>
    </main>
</template>



<style>

#head article {
    color: var(--text-secondary-gray-400, #888);
    font-family: Gilroy;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; 
    height: 100%;
    /* justify-content: center; */
    padding-left: 10px;
    align-items: center;
    background: #FFF;
    display: flex;
    /* justi */
}

.grid_cell input::before{
    border: 1px solid var(--text-system-blue-400, #00A3FF);
                        background: var(--text-system-blue-400, #00A3FF);
}

.grid_cell{
    border-bottom: 1px solid #E1E1E1;
    display: flex;
    align-items: center;
    justify-content: left;
    align-content: center;
    text-align: left;
    padding-left: 10px;
    background-color: #FFF;
    /* Primary */
    color: var(--text-primary-gray-700, #313131);
    font-family: Gilroy;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

#head article{
    text-align: center;
}
a.nav{
    color: #888888;
    margin-left: 22px;
}

#main{
    left: 272px;
    position: absolute;
    width: calc(100% - 282px );
    border-radius: 6px;
    border: 1px solid var(--gray-gray-150, #E1E1E1);
    background: #FFF;
    top: 120px;
}


head article{
    color: var(--text-secondary-gray-400, #888);
    border-right: 1px solid #888;
    font-family: Gilroy;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.select_box {
    display: flex;
    width: 203px;
    height: 32px;
    justify-content: center;
    border-radius: 2px;
    align-items: center;
    border-radius: 2px;
    border: 1px solid var(--text-placeholder-gray-300, #BCBCBC);
    background: var(--gray-white, #FFF);
    margin-right:48px;
}

.select_box option{
    border-radius: 4px;
    background: #FFF;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.10);
    height:48px;
}

.tag_franchise {
    color: var(--text-system-blue-400, #00A3FF);
    text-align: center;
    font-family: Gilroy;
    font-size: 14px;
    border-radius: 3px;
    border: 1px solid var(--text-system-blue-400, #00A3FF);
    background: #FFF;
}

.tag_franchise .fasol{
    color: #FFA800;
}

.an_tag{
    text-align: center;
    font-family: Gilroy;
    font-size: 14px;
    border-radius: 3px;
    border: 0px;
}
</style>