<script setup>
import {defineModel} from 'vue'

const model = defineModel()

</script>

<template>
  
  <div>
    <label class="containerr">
      <input type="checkbox" v-model="model">
      <span class="checkmark"></span>
    </label>
  </div>

</template>


<style scoped>  



.containerr { 
  display: flex;
  padding-top: 12px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: absolute;
}

/* Hide the browser's default checkbox */
.containerr input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 12px;
  width: 12px;
  margin-top: -4px;
  margin-left: 6px;
  background-color: #eee;
  border-radius: 2px;
  border-radius: 2px;
  border: 1px solid var(--gray-gray-150, #E1E1E1);
  background: var(--secondary-b-0, #FFF);
}

/* On mouse-over, add a grey background color */
.containerr:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.containerr input:checked ~ .checkmark {
  border-radius: 2px;
  border: 1px solid var(--text-system-blue-400, #00A3FF);
  background: var(--text-system-blue-400, #00A3FF);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.containerr input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.containerr .checkmark:after {
  left: 2px;
  top: -1px;
  width: 5px;
  height: 7px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
</style>