<script setup>
    import '@vuepic/vue-datepicker/dist/main.css';
    import {usePlusStore} from './store'
    import {storeToRefs} from 'pinia'
import { watch } from 'vue';

    const plusStore = usePlusStore();


    const {
        firstFile,
        secondFile
    } = storeToRefs(plusStore);


    const fileChenged =async (number, file) => {
        if (number == 1) {
            firstFile.value = file;
        } else {
            secondFile.value = file;
        }
        console.log(file)
    }

    const requestPlus = async () => {
        const rez = await plusStore.requestPlusFile();
        let blob = new Blob([rez], { type: 'application/json' })
        let link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = 'importData.json'
        link.click()
    }

    console.log('')

    watch(() => {
        firstFile,
        secondFile
    })
</script>

<template>
    <h2 style="
    position: absolute;
    left: 272px;
    ">Таблица магазинов</h2>
    <main>
        <section>
            <article class = 'file_art'>
                <span>
                    Первый файл
                </span>
                <label id="file_input" v-if="!firstFile">
                    <span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                            <rect x="5.25003" width="1.5" height="12" rx="0.75" fill="#00A3FF"/>
                            <rect y="6.75" width="1.5" height="12" rx="0.75" transform="rotate(-90 0 6.75)" fill="#00A3FF"/>
                        </svg>
                    </span>
                    <input  @change = '(event) => {
                        fileChenged(1, event.target.files[0])
                        console.log(firstFile)
                        }' type="file">
                    <span>
                        Выберите файл
                    </span>
                </label>
                <label id="file_is_chose" v-else>
                    <article class = 'svg_block' style="
                    margin-right: 12px;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="18" viewBox="0 0 14 18" fill="none">
                        <path d="M13.701 4.37316L9.48231 0.154512C9.38384 0.0560039 9.24944 0 9.10938 0H1.72656C0.85423 0 0.144531 0.709699 0.144531 1.58203V16.418C0.144531 17.2903 0.85423 18 1.72656 18H12.2734C13.1458 18 13.8555 17.2903 13.8555 16.418V4.74609C13.8555 4.6023 13.7951 4.46727 13.701 4.37316ZM9.63672 1.80046L12.055 4.21875H10.1641C9.87329 4.21875 9.63672 3.98218 9.63672 3.69141V1.80046ZM12.2734 16.9453H1.72656C1.43579 16.9453 1.19922 16.7087 1.19922 16.418V1.58203C1.19922 1.29125 1.43579 1.05469 1.72656 1.05469H8.58203V3.69141C8.58203 4.56374 9.29173 5.27344 10.1641 5.27344H12.8008V16.418C12.8008 16.7087 12.5642 16.9453 12.2734 16.9453Z" fill="black"/>
                        <path d="M10.1641 7.45312H3.83594C3.5447 7.45312 3.30859 7.68923 3.30859 7.98047C3.30859 8.2717 3.5447 8.50781 3.83594 8.50781H10.1641C10.4553 8.50781 10.6914 8.2717 10.6914 7.98047C10.6914 7.68923 10.4553 7.45312 10.1641 7.45312Z" fill="black"/>
                        <path d="M10.1641 9.5625H3.83594C3.5447 9.5625 3.30859 9.79861 3.30859 10.0898C3.30859 10.3811 3.5447 10.6172 3.83594 10.6172H10.1641C10.4553 10.6172 10.6914 10.3811 10.6914 10.0898C10.6914 9.79861 10.4553 9.5625 10.1641 9.5625Z" fill="black"/>
                        <path d="M10.1641 11.6719H3.83594C3.5447 11.6719 3.30859 11.908 3.30859 12.1992C3.30859 12.4905 3.5447 12.7266 3.83594 12.7266H10.1641C10.4553 12.7266 10.6914 12.4905 10.6914 12.1992C10.6914 11.908 10.4553 11.6719 10.1641 11.6719Z" fill="black"/>
                        <path d="M8.05469 13.7812H3.83594C3.5447 13.7812 3.30859 14.0174 3.30859 14.3086C3.30859 14.5998 3.5447 14.8359 3.83594 14.8359H8.05469C8.34592 14.8359 8.58203 14.5998 8.58203 14.3086C8.58203 14.0174 8.34592 13.7812 8.05469 13.7812Z" fill="black"/>
                        </svg>
                    </article>
                    <a>{{firstFile.name}}</a>
                    <input  @change = '(event) => {
                    fileChenged(2, event.target.files[0])
                    }' type="file">
                    <span>
                        Заменить
                    </span>
                </label>
            </article>
            <article class = 'file_art'>
                <span>
                    Второй файл
                </span>
                <label id="file_input" v-if="!secondFile">
                    <span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                            <rect x="5.25003" width="1.5" height="12" rx="0.75" fill="#00A3FF"/>
                            <rect y="6.75" width="1.5" height="12" rx="0.75" transform="rotate(-90 0 6.75)" fill="#00A3FF"/>
                        </svg>
                    </span>
                    <input  @change = '(event) => {
                        fileChenged(2, event.target.files[0])
                        }' type="file">
                    <span>
                        Выберите файл
                    </span>
                </label>
                <label id="file_is_chose" v-else>
                    <article class = 'svg_block' style="
                    margin-right: 12px;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="18" viewBox="0 0 14 18" fill="none">
                        <path d="M13.701 4.37316L9.48231 0.154512C9.38384 0.0560039 9.24944 0 9.10938 0H1.72656C0.85423 0 0.144531 0.709699 0.144531 1.58203V16.418C0.144531 17.2903 0.85423 18 1.72656 18H12.2734C13.1458 18 13.8555 17.2903 13.8555 16.418V4.74609C13.8555 4.6023 13.7951 4.46727 13.701 4.37316ZM9.63672 1.80046L12.055 4.21875H10.1641C9.87329 4.21875 9.63672 3.98218 9.63672 3.69141V1.80046ZM12.2734 16.9453H1.72656C1.43579 16.9453 1.19922 16.7087 1.19922 16.418V1.58203C1.19922 1.29125 1.43579 1.05469 1.72656 1.05469H8.58203V3.69141C8.58203 4.56374 9.29173 5.27344 10.1641 5.27344H12.8008V16.418C12.8008 16.7087 12.5642 16.9453 12.2734 16.9453Z" fill="black"/>
                        <path d="M10.1641 7.45312H3.83594C3.5447 7.45312 3.30859 7.68923 3.30859 7.98047C3.30859 8.2717 3.5447 8.50781 3.83594 8.50781H10.1641C10.4553 8.50781 10.6914 8.2717 10.6914 7.98047C10.6914 7.68923 10.4553 7.45312 10.1641 7.45312Z" fill="black"/>
                        <path d="M10.1641 9.5625H3.83594C3.5447 9.5625 3.30859 9.79861 3.30859 10.0898C3.30859 10.3811 3.5447 10.6172 3.83594 10.6172H10.1641C10.4553 10.6172 10.6914 10.3811 10.6914 10.0898C10.6914 9.79861 10.4553 9.5625 10.1641 9.5625Z" fill="black"/>
                        <path d="M10.1641 11.6719H3.83594C3.5447 11.6719 3.30859 11.908 3.30859 12.1992C3.30859 12.4905 3.5447 12.7266 3.83594 12.7266H10.1641C10.4553 12.7266 10.6914 12.4905 10.6914 12.1992C10.6914 11.908 10.4553 11.6719 10.1641 11.6719Z" fill="black"/>
                        <path d="M8.05469 13.7812H3.83594C3.5447 13.7812 3.30859 14.0174 3.30859 14.3086C3.30859 14.5998 3.5447 14.8359 3.83594 14.8359H8.05469C8.34592 14.8359 8.58203 14.5998 8.58203 14.3086C8.58203 14.0174 8.34592 13.7812 8.05469 13.7812Z" fill="black"/>
                        </svg>
                    </article>
                    <a>{{secondFile.name}}</a>
                    <input  @change = '(event) => {
                    fileChenged(id, event.target.files[0]) 
                    error_filter = "0000"
                    }' type="file">
                    <span>
                        Заменить
                    </span>
    </label>
            </article>

            <button
            class="go"
            :class="{
                canGo:firstFile && secondFile
            }"
            @click="
                requestPlus();
            ">
                Соеденить
            </button>
        </section>
        
    </main>
</template>



<style scoped>

#file_input{
    display: flex;
    padding: 8px 41px;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    border: 1px dashed var(--text-system-blue-400, #00A3FF);
    background: #FFF;
    color: var(--text-system-blue-400, #00A3FF);
    font-family: Gilroy;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

#file_input input{
    position: absolute;
	z-index: -1;
	opacity: 0;
	display: block;
	width: 0;
	height: 0;
}

.file_art{ 
    display: flex;
    flex-direction: column;
    width: 250px;
}

section{
    margin-left: 22px
}

.file_art span{
    margin-top: 10px;
    margin-bottom: 10px;
}

main{
    left: 272px;
    position: absolute;
    width: calc(100% - 282px );
    border-radius: 6px;
    border: 1px solid var(--gray-gray-150, #E1E1E1);
    background: #FFF;
    top: 120px;
}



#head article {
    color: var(--text-secondary-gray-400, #888);
    font-family: Gilroy;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; 
    height: 100%;
    /* justify-content: center; */
    padding-left: 10px;
    align-items: center;
    background: #FFF;
    display: flex;
    /* justi */
}
</style>