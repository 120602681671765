<script setup>
    import { ref } from'vue';
    import { useUserStore } from '@/stores/import.store';
    // import { useGlobalErrorStore } from '@/stores/globalerror'
    import { notify } from "notiwind"
    import { storeToRefs } from 'pinia';
    import BranchNav from '@/components/ImportComponents/BranchNav.vue';
    import eSelectBox from '@/components/vue3-ecomponents/select_box.vue';
    import eCheckBox from '@/components/vue3-ecomponents/checkbox_man.vue';


    // const globalErrorStore = useGlobalErrorStore();
    
    const userStore = useUserStore();
    const load = ref(false);
    const open_settings = ref(false);

    const {
        branches,
        current_branch,
        error_filter,
        current_ychetka,
        set_double_barcode,
        set_double_plu,
        set_bad_remain,
        change_bad_remainder,
    } = storeToRefs(userStore);

    const ychetki = [
        {
            text:"1С", value:'1C'
        },{
            text:"Smarket", value:'Smarket'
        },{
            text:"Любая", value:''
        },
    ]

    async function fileChenged(id, file) {
        // notify({
        //     group: "foo",
        //     title: "Success",
        //     text: "file load"
        //     }, 10000) 
        console.log('load');
        current_branch.value = id;
        load.value=true;
        branches.value.map((branch) => {
            // console.log (branch)
            if (branch.id == id) branch.file = file;
        })
        try {
            const err = await userStore.RequestSaveBase(id);
            // console.log("wedwe f" + err);
            if (err?.errors) {
                notify({
                group: "foo",
                text: err.errors
                }, 10000) 
            } else {
                try {   
                await userStore.RequestErrors(id);
                
                } catch (error) {
                    branches.value.map((branch) => {
                    // console.log (branch)
                        if (branch.id == id) branch.file = undefined;
                    })
                    notify({
                    group: "foo",
                    title: error,
                    text: error
                    })
                    console.log(error);
                    // load.value=false
                }
            }
        } catch(error) {
            console.log(error);
            load.value=false
            branches.value.map((branch) => {
            // console.log (branch)z
                if (branch.id == id) branch.file = undefined;
            })
        }   

        load.value = false;
       
    }

    const fixFile = async (id) => {
        const file = ( await userStore.RequestFixFile(id));
        let blob = new Blob([file], { type: 'application/json' })
        let link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = 'importData.json'
        link.click()
    }

    const vars = [
        {text:'Все ошибки',value:'0000'},
        {text:'ARTICULE',value:'0100'},
        {text:'MARKING_TYPE',value:'0200'},
        {text:'NAME',value:'0300'},
        {text:'NAME_MASURMENT',value:'0400'},
        {text:'BARCODE',value:'0500'},
        {text:'QUANTITY',value:'0600'},
        {text:'NETTO',value:'0700'},
        {text:'CLASSIF',value:'0800'},
        {text:'PRICE_ROZ',value:'0900'},
        {text:'PRICE_SEB',value:'1000'},
        {text:'ALC_CODE',value:'1100'},  
        {text:'MERC_CODE',value:'1200'},
        {text:'CL_ARTICULE',value:'1300'},
        {text:'CL_NAME',value:'1400'},
        {text:'NDS',value:'1500'},
        {text:'SRC_GOND',value:'1600'},
        {text:'SCALE_CODE',value:'1700'},
        {text:'SCALE_MASSAGE',value:'1800'},
    ]
</script>

<template>
    <article 
     style = '
     width: calc(100% - 225px);
     margin-top: 4px;
     display: flex;
     flex-direction: row;
     justify-content: right;'>
        <button class = 'go' 
        :class='{canGo: true, Error:false}'
        @click="() => {
            userStore.chengeNavIndex(4);
            if (isLogin) $router.push('/import/compaund')
        }"><a>Продолжить</a></button>
    </article>
    <BranchNav></BranchNav>
    <section v-if='!load' >
        
        <section :key = 'id' v-for = '{file,id,products} in branches'>
            <section v-if="id==current_branch">
                <section class="main_meny">
                    <h2>Файл</h2>   
                    <label id="file_input" v-if="!file">
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                <rect x="5.25003" width="1.5" height="12" rx="0.75" fill="#00A3FF"/>
                                <rect y="6.75" width="1.5" height="12" rx="0.75" transform="rotate(-90 0 6.75)" fill="#00A3FF"/>
                            </svg>
                        </span>
                        <input  @change = '(event) => {
                            // userStore.ChengeFile(event.target.files[0])
                            fileChenged(id, event.target.files[0])
                            }' type="file">
                        <span>
                            Выберите файл
                        </span>
                    </label>
                    <label id="file_is_chose" v-else>
                        <article class = 'svg_block' style="
                        margin-right: 12px;">
                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="18" viewBox="0 0 14 18" fill="none">
                            <path d="M13.701 4.37316L9.48231 0.154512C9.38384 0.0560039 9.24944 0 9.10938 0H1.72656C0.85423 0 0.144531 0.709699 0.144531 1.58203V16.418C0.144531 17.2903 0.85423 18 1.72656 18H12.2734C13.1458 18 13.8555 17.2903 13.8555 16.418V4.74609C13.8555 4.6023 13.7951 4.46727 13.701 4.37316ZM9.63672 1.80046L12.055 4.21875H10.1641C9.87329 4.21875 9.63672 3.98218 9.63672 3.69141V1.80046ZM12.2734 16.9453H1.72656C1.43579 16.9453 1.19922 16.7087 1.19922 16.418V1.58203C1.19922 1.29125 1.43579 1.05469 1.72656 1.05469H8.58203V3.69141C8.58203 4.56374 9.29173 5.27344 10.1641 5.27344H12.8008V16.418C12.8008 16.7087 12.5642 16.9453 12.2734 16.9453Z" fill="black"/>
                            <path d="M10.1641 7.45312H3.83594C3.5447 7.45312 3.30859 7.68923 3.30859 7.98047C3.30859 8.2717 3.5447 8.50781 3.83594 8.50781H10.1641C10.4553 8.50781 10.6914 8.2717 10.6914 7.98047C10.6914 7.68923 10.4553 7.45312 10.1641 7.45312Z" fill="black"/>
                            <path d="M10.1641 9.5625H3.83594C3.5447 9.5625 3.30859 9.79861 3.30859 10.0898C3.30859 10.3811 3.5447 10.6172 3.83594 10.6172H10.1641C10.4553 10.6172 10.6914 10.3811 10.6914 10.0898C10.6914 9.79861 10.4553 9.5625 10.1641 9.5625Z" fill="black"/>
                            <path d="M10.1641 11.6719H3.83594C3.5447 11.6719 3.30859 11.908 3.30859 12.1992C3.30859 12.4905 3.5447 12.7266 3.83594 12.7266H10.1641C10.4553 12.7266 10.6914 12.4905 10.6914 12.1992C10.6914 11.908 10.4553 11.6719 10.1641 11.6719Z" fill="black"/>
                             <path d="M8.05469 13.7812H3.83594C3.5447 13.7812 3.30859 14.0174 3.30859 14.3086C3.30859 14.5998 3.5447 14.8359 3.83594 14.8359H8.05469C8.34592 14.8359 8.58203 14.5998 8.58203 14.3086C8.58203 14.0174 8.34592 13.7812 8.05469 13.7812Z" fill="black"/>
                            </svg>
                        </article>
                        <a>{{file.name}}</a>
                        <input  @change = '(event) => {
                         fileChenged(id, event.target.files[0]) 
                         error_filter = "0000"
                        }' type="file">
                        <span>
                            Заменить
                        </span>
                    </label>   
                </section>
                <section v-if = 'products?.length > 0 || error_filter != "0000"' 
                    class = ' main_meny'
                    style="
                        
                    ">
                    <section  class = 'easy_flex_line'
                    >
                        <article class = 'svg_block' style="
                            width: 22px;
                            height: 22px;
                            margin-right: 16px;">
                            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                                <path d="M19.4939 19.2482H2.50527C2.17778 19.2482 1.87517 19.0735 1.71143 18.7898C1.54769 18.5062 1.54769 18.1568 1.71143 17.8732L10.2053 3.20652C10.3691 2.92338 10.6715 2.74902 10.9986 2.74902C11.3258 2.74902 11.6281 2.92338 11.792 3.20652L20.2859 17.8732C20.4495 18.1567 20.4496 18.5059 20.2861 18.7894C20.1226 19.073 19.8203 19.2479 19.4929 19.2482H19.4939ZM11 5.49819L4.0966 17.4149H17.9053L11 5.49819ZM11.9121 13.7491H10.0788V9.16486H11.9121V13.7491Z" fill="#E53835"/>
                                <path d="M10.0834 14.6667H11.9167V16.5H10.0834V14.6667Z" fill="#E53835"/>
                            </svg>
                        </article>
                        <h3>Исправьте ошибки в файле и загрузите повторно</h3>
                    </section>
                   
                    <section class = 'head_action_bar_beetween'>
                        <eSelectBox
                            @change="(value) => {
                                error_filter = value; 
                                userStore.RequestErrors(id);
                                }"
                            :value="error_filter"
                            :input="false"
                            type="'select'"
                            :width="200"
                            style="z-index:6;"
                            :variants="vars">
                        </eSelectBox>
                        <section>
                        <button class = 'base_button row'>
                            <a @click = "() => {
                                    fixFile(id);
                                }">
                                Исправить автоматически
                            </a>
                            <div
                            @click="open_settings=!open_settings" style='padding-top: 4px; padding-left: 6px;'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                <circle cx="1" cy="6" r="1" fill="white"/>
                                <circle cx="6" cy="6" r="1" fill="white"/>
                                <circle cx="11" cy="6" r="1" fill="white"/>
                                </svg>
                            </div>
                        </button>
                            <section id="setting_block" v-if="open_settings">
                            <h4>Нaстройки аватиаматического исправления</h4>
                                <section
                                style="
                                flex-direction: column;"><span style="margin-left: 4px;">Товароучётка</span>
                                <eSelectBox
                                @change="(current_variant) => {
                                                current_ychetka = current_variant;
                                                console.log(current_ychetka, 'ychetka filter')
                                            }"
                                :input="false"
                                :type="'select'"
                                :variants="ychetki"
                                
                                :value="current_ychetka"
                                :width="200"
                                style="margin-left: 4px;
                                position: absolute;
                                top:102px;"></eSelectBox></section>
                                <section class='row'
                                style='margin-top: 36px;'>
                                    <eCheckBox 
                                    v-model="set_double_barcode"
                                    @click="() => {console.log(set_double_barcode)}"
                                    ></eCheckBox>
                                    <span>Удалить завдоения штрихкодов</span>
                                </section>
                                <section class='row'>
                                    <eCheckBox
                                    v-model="set_double_plu"></eCheckBox>
                                    <span>Удалить задвоения плу</span>
                                </section>
                                <section class='row'>
                                    <eCheckBox 
                                    v-model="set_bad_remain"></eCheckBox>
                                    <span>Удалить товары с отрицательным или нулевым остатком</span>
                                </section>
                                <section class='row'>
                                    <eCheckBox
                                    v-model="change_bad_remainder"></eCheckBox>
                                    <span>Заменить некоректную форму записи остатков</span>
                                </section>
                            </section>
                        </section>
                     </section>
                     
                    <section class="isolaited_meny" id = 'erros_list'>
                        <section v-for = 'product in products' :key='product.ARICULE' >
                            <section v-for = 'error in product.errors' :key='error.text2' class = 'red_block'>
                                <h3>{{ product.NAME + ' ' +  product.ARTICUL }}</h3>
                                <a>{{ error.text }}</a>
                            </section>
                        </section>
                    </section>
                </section>
                <section v-else-if="!file">

                </section>
                <section  v-else class = ' main_meny'>
                    <section class = 'easy_flex_line'>
                        <article class = 'svg_block'
                        style = '
                        width: 22px;
                        height: 22px;
                        margin-right: 16px;'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="14" viewBox="0 0 22 14" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M20.8122 0.614435C21.4532 1.20899 21.4532 2.2232 20.8122 2.81776L9.38917 13.4123C8.82898 13.9319 7.96302 13.9319 7.40283 13.4123L1.18781 7.64805C0.546759 7.05349 0.546759 6.03928 1.18781 5.44472C1.76412 4.91021 2.65502 4.91021 3.23133 5.44472L8.396 10.2348L18.7687 0.614435C19.345 0.0799177 20.2359 0.0799179 20.8122 0.614435Z" fill="#00CB91"/>
                            </svg>
                        </article>
                    <!-- <img scr='https://www.figma.com/file/f3HsPCtFkN5q6TbndHAqQs/%F0%9F%8C%A9-%D0%90%D0%B4%D0%BC%D0%B8%D0%BD-%D0%BF%D0%B0%D0%BD%D0%B5%D0%BB%D1%8C-%D1%8D%D0%BD%D1%82%D0%B5%D1%80%D1%81%D0%B0%D0%B9%D1%82-%2F-admin-entersight?type=design&node-id=211-9567&mode=dev'> -->
                    <h3>Ошибок не обнаружено</h3> 
                    </section>
                </section>
            </section>
        </section>
    </section>
    <section v-else
        style="
        display: flex;
        align-items: center;
        justify-content: center;
        width: calc(100% - 300px);
        /* border: 1px solid black; */
        position: absolute;
        left: 272px;
        height: 300px;">
        <section  
        style = '
        display: flex;
        margin-left: auto;
        margin-right: auto;
        justify-content: center;
        ' 
        class = 'loader'>
            <a style = '
            margin-left: auto;
            margin-right: auto;
            '></a>
        </section>
    </section>
</template>


<style scoped>

#file_input{
    display: flex;
    padding: 8px 41px;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    border: 1px dashed var(--text-system-blue-400, #00A3FF);
    background: #FFF;
    color: var(--text-system-blue-400, #00A3FF);
    font-family: Gilroy;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

#erros_list{
    height: calc(100% - 600px);
    width: 100%;
}
    .lds-dual-ring {
    display: inline-block;
    width: 80px;
    height: 80px;
  }

  #setting_block{
    position: absolute;
    margin-left: -267px;
    border-radius: 4px;
    background: #FFF;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.10);
    border-right-width: 2px;
    padding: 12px;
    border-radius: 6px;
    margin-top: 4px;
  }


  #setting_block section{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }
  
  #setting_block span{
    margin-top: 4px;
    margin-left: 32px;
  }
  .lds-dual-ring:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }

  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  #file_input input{
    position: absolute;
	z-index: -1;
	opacity: 0;
	display: block;
	width: 0;
	height: 0;
  }

  #file_input span{
    margin: 0px 3px;
  }

  #file_is_chose{
    background: var(--gray-gray-50, #EFEFEF);
    border-right: var(--gray-gray-150, #E1E1E1);
    border-right-width: 2px;
    display: flex;
    padding: 0px 10px 0px 12px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 32px;
  }

  #file_is_chose input{
    position: absolute;
	z-index: -1;
	opacity: 0;
	display: block;
	width: 0;
	height: 0;
  }
  
  #file_is_chose span{
    color: #00A3FF;
    margin-left: 3px;
  }

</style>