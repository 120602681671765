<script setup>
import { LOGIN_USER } from "../gql";
import { ref } from 'vue';
import { useRootStore } from "@/stores/root.store";
import { useMutation } from '@vue/apollo-composable';
import { storeToRefs } from "pinia";
import router from "@/router";

const password = ref('');
const login = ref('');

const rootStore = useRootStore();

const {
    isLogin,
} = storeToRefs(rootStore);

const { mutate: LoginUser, onDone: onDoneLogin } = useMutation(LOGIN_USER,
() => ({
  variables: {
    password:password.value,
    email:login.value
  },
}))

onDoneLogin(async result => {
  await rootStore.Login(result.data);
  console.log(isLogin)
  if (isLogin) {
    router.push('/')
  }
})

</script>

<template>
    <section
    style = "
    display: flex;
    overflow: hidden;
    flex-direction: row;
    height: 100%;
    margin-top: -15px;
    width: 100%;
    font-weight: 500;
    font-size: 32px;">
        <main style="
        display: flex;
        flex-direction: column;
        width: 50%;
        flex-direction: column;
        justify-content: space-between;
        padding:   8vh 10vh  5vh 3vh;
        align-items: center;
        align-self: stretch;
        ">
            <section style="
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-content: center;
            align-items: center;
            ">
                <img 
                style="padding-left: 28px;
                "
            
                src="@/assets/admin-pannel.png" alt="">
                <h3>Вход</h3>
                <section style="
                width: 100%;">
                    <article style="margin-top: 4vh;">
                        <p>Электроннная почта</p>
                        <input type="email" name="login" id="login"
                        v-model="login"
                        class = 'aut_input'>
                    </article>
                    <article  style="
                    margin-top: 4vh;">
                        <p>Пароль</p>
                        <input type="password" name="password" id="password"
                        v-model="password"
                        class = 'aut_input'>
                    </article>
                    <p>Забыл пароль, ну лох, что сказать</p>

                    <button class = 'base_button'
                    @click="
                    () => {
                        LoginUser()
                    }
                    "
                    style="
                    width: 54vh;
                    height: 42px;
                    margin-top: 3vh;">
                        <a
                        style = "color: var(--gray-white, #FFF);
                        font-family: Gilroy;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;"
                        >
                        Войти
                        </a>
                    </button>

                </section>
                <p
                style="color: var(--text-secondary-gray-400, #888);
                text-align: center;
                font-family: Gilroy;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: 150%; /* 27px */">
                    Ещё нет аккаунта ? <a
                        style="color: var(--text-system-blue-400, #00A3FF);
                        font-family: Gilroy;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 150%;
                        text-decoration-line: underline;">
                        Зарегистрироваться
                    </a>
                </p>
            </section>

            <p
            style="
           ">© 2021-2022  ООО «Иволга-С»</p>
        </main>
        <section style="
            background: var(--secondary-b-0, #FFF);
            box-shadow: -20px 0px 36px 0px rgba(0, 0, 0, 0.06);
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 50%;
            height: 100vh;
            padding: 0px;">
        <img src="@/assets/web-dev-colour.png"
        style="width: 650px;
        height: 487.5px;">
        <a style="color: #000;
                text-align: center;
                padding-left: 25px;
                font-family: Gilroy;
                font-size: 22px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;">Добро пожаловать в админ-панель</a>
        </section>
    </section>
</template>

<style scoped>

input.aut_input{
    height: 42px;
    width: 50vh;
    margin-top: 0px;
    display: flex;
    padding: 0px 2vh 0px 2vh;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    border: 1px solid var(--gray-gray-150, #E1E1E1);
    background: var(--gray-white, #FFF);
}

a{
    color: var(--text-primary-gray-700, #313131);
    font-family: Gilroy;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 114.286% */   
}

h3{
    margin-top: 2vh;
    margin-bottom: 0px;
}

p{
    color: var(--text-primary-gray-700, #313131);
    font-family: Gilroy;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 114.286% */   
}
</style>