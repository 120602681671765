
export const AdmCompaniesV2 = `
    query AdmComp($blocked: Boolean!, $franchise: String) {
      AdmCompaniesV2(blocked: $blocked, franchise: $franchise) {
        companies {
          id
          name
          like
          user {
            email
            phone
            lastActivity
          }
          branches {
            name
            subscription {
              cancel_date
            }
          }
          blocked
          branches_count
          createdDate
          checkAnalytics {
            average
            quantity
            returns
            revenue
          }
          mercury
          egais
          dirty_space
          cashes
          is_alive
          license_end
          analyticsMargin {
            totalSum {
              margin
              avgMargin
            }
          }
        }
        total_alive
      }
    }
  `