import { createRouter, createWebHistory } from 'vue-router'
import LayoutView from '@/views/LayoutView/LayoutView.vue'
import ImportView from '@/views/ImportView/ImportView.vue'
import BranchTableView from '@/views/BranchTableView/BranchTableView.vue'
import AgnetLogsView from '@/views/AgnetLogsView/AgnetLogsView.vue'
import ImportRoutes from '@/views/ImportView/router/index.js'

import MainListView from  '@/views/MainListView/MainListView.vue'
import ClientsView from  '@/views/ClientsView/ClientsView'
import AuthLayout from  '@/views/AuthVIews/AutLayout.vue'
import AuthRoutes from '@/views/AuthVIews/authrouter'
import PlusFileView from '@/views/PlusFileView/PlusFileView.vue'


const routes = [
  {
    path:'/',
    name:'layout',
    component: LayoutView,
    children: [
        {
          path:'/import',
          name:'importView',
          component: ImportView,
          children: ImportRoutes
        },
        {
          path:'/mainlist',
          name:'mainList',
          component: MainListView,
          children:[]
        },
        {
          path:'/clients',
          name:'clients',
          component: ClientsView,
          children:[]
        },
        {
          path:'/branchTable',
          name:'branchTable',
          component: BranchTableView,
          children:[]
        },
        {
          path:'/agentlogstable',
          name:'agentlogstable',
          component: AgnetLogsView,
          children:[]
        },
        {
          path:'/plus',
          name:'plus',
          component: PlusFileView
        }
    ]
  },
  {
    path:'/aut',
    name:'authorizationView',
    component:AuthLayout,
    children:AuthRoutes
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
