
import TokenInput from '@/components/TokenInput.vue'
import FileUploadList from '@/components/FileUploadList.vue'
import StorageList from '@/components/StorageList.vue'
import UploadList from '@/components/UploadList.vue'
import CompaundFileUploadList from '@/components/ImportComponents/CompaundFileUploadList.vue'


const ImportRoutes = [
            {
              path:'/import/token',
              name:'token',
              component: TokenInput
            },
            {
              path:'/import/storage',
              name:'storage',
              component: StorageList, 
            },
            {
              path:'/import/file',     
              name:'file',
              component: FileUploadList,
            },
            {
              path:'/import/upload',
              name:'upload',
              component: UploadList,
            },
            {
              path:'/import/compaund',
              name:'compaund',
              component: CompaundFileUploadList,
            }
          ]
          
export default ImportRoutes
