<script setup>
import {defineProps, onMounted, defineEmits} from "vue"


const props = defineProps({
    placehold: String,
    value: String,
})

const emits = defineEmits(["change"])

onMounted(() => {
    const input_text = document.getElementById("input_text");
    input_text.placeholder = props.placehold|| "Ваш текст";
})

console.log(emits)


</script>

<template>
    <main>
        
        
        <input
        @click="() => {
            
        }"
        @change="(event) => {$emit('change',event.target.value)}"
        id="input_text">
        
    </main>
</template>

<style scoped> 

#main_text{
    color: var(--text-placeholder-gray-300, #BCBCBC);
    font-family: Gilroy;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px; /* 100% */
}

main{
    display: flex;
    height: 16px;
    justify-content: left;
    align-items: left;
    padding: 8px 18px;
    border-radius: 2px;
    border: 1px solid var(--text-placeholder-gray-300, #BCBCBC);
    background: var(--gray-white, #FFF);
}


input{
    border: 0px;
}

input :focus{
    border: 0px;
}

input :active{
    border: 0px;
}

</style>