<script setup>
    import { useUserStore } from '@/stores/import.store'
    import { storeToRefs } from 'pinia'
    import BranchNav from '@/components/ImportComponents/BranchNav.vue'
    import eSelectBox from '@/components/GlobalComponent/eSelectBox.vue'
    const userStore = useUserStore();

    const {
        branches,
        current_branch,
        error_filter
    } = storeToRefs(userStore);

    async function fileChenged(id,file) {
        console.log('load');
        current_branch.value = id;
        userStore.ChengeFile(file);
        startAnim();
        await userStore.RequestCompaundSaveBase();
        await userStore.RequestCompaundErrors();
        FinishAnim();
    }

    const startAnim = () => {

    }

    const FinishAnim = () => {

    }
</script>

<template>
    <article 
     style = '
     width: calc(100% - 225px);
     margin-top: 16px;
     display: flex;
     flex-direction: row;
     justify-content: right;'>
        <button class = 'go' 
        :class='{canGo: true, Error:false}'
        @click="() => {
            userStore.chengeNavIndex(5);
            $router.push('/import/upload');
        }"><a>Продолжить</a></button>
    </article>
    <BranchNav></BranchNav>
    <section :key = 'id' v-for = '{name,id,products} in branches'>
        <section v-if="id==current_branch">
            <section class="main_meny ">
                <h2>Файл Компоновки</h2>
            
                <a>{{ name }}</a>
                <input @change = '(event) => {
                        // userStore.ChengeFile(event.target.files[0])
                        fileChenged(id, event.target.files[0])
                        }' type="file">
                
            </section>
            <section v-if = 'products?.length > 0' class = ' main_meny'>
                <h3>Исправьте ошибки в файле и загрузите повторно</h3>
                <eSelectBox :variants="['1','2','3','4','5','6','7','8','9','10','11']" :param ='error_filter'></eSelectBox>
                <section class="isolaited_meny" id = 'erros_list'>
                    <section v-for = 'product in products' :key='product.ARICULE' >
                        <section v-for = 'error in product.errors' :key='error.id' class = 'red_block'>
                            <h3>{{ product.NAME + ' ' +  product.ARTICUL }}</h3>
                            <a>{{ error }}</a>
                        </section>
                    </section>
                </section>
            </section>
            <section  v-else class = ' main_meny'>
                <img src='https://www.figma.com/file/f3HsPCtFkN5q6TbndHAqQs/%F0%9F%8C%A9-%D0%90%D0%B4%D0%BC%D0%B8%D0%BD-%D0%BF%D0%B0%D0%BD%D0%B5%D0%BB%D1%8C-%D1%8D%D0%BD%D1%82%D0%B5%D1%80%D1%81%D0%B0%D0%B9%D1%82-%2F-admin-entersight?type=design&node-id=211-9567&mode=dev'>
                <h3>Ошибок не обнаружено</h3> 
            </section>
        </section>
    </section>
</template>


<style>
#erros_list{
    height: 450px;
}
</style>