<script setup>
    import { useRouter } from 'vue-router'
    // import { useGlobalErrorStore } from '@/stores/globalerror'
    // import { storeToRefs } from 'pinia'
    // import errorComponent from '@/components/GlobalComponent/ErrorComponent.vue'

    // const globalErrorStore = useGlobalErrorStore();
    const router = useRouter()
    // const route = useRoute()



    // const {
    //   errors
    // } = storeToRefs(globalErrorStore);

    // globalErrorStore.GetError("хуй")

    const checkToAut = () => {
      if (!localStorage.getItem("admin_token")) {
        router.push('auth/auth');
      }
    }

    checkToAut();
</script>

<template>
   <NotificationGroup group="foo">
    <div
    class="fixed inset-0 flex items-start justify-end p-6 px-4 py-6 pointer-events-none"  
  >
    <div class="w-full max-w-sm"
   >
      <Notification
        v-slot="{ notifications }"
        enter="transform ease-out duration-300 transition"
        enter-from="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-4"
        enter-to="translate-y-0 opacity-100 sm:translate-x-0"
        leave="transition ease-in duration-500"
        leave-from="opacity-100"
        leave-to="opacity-0"
        move="transition duration-500"
        move-delay="delay-300"
      >
        <section
        section  style="
        width: 100%;
        position: fixed;
        height: 50px;
        display: flex;
        top:80%;
        left:0px;
        justify-content: center;
        align-items: center;
        border-radius: 1ox solid black;
      "
          class="flex w-full max-w-sm mx-auto mt-4 overflow-hidden bg-white rounded-lg shadow-md"
          v-for="notification in notifications"
          :key="notification.id"
        >
          <section class="px-4 py-2 -mx-3">
            <section class="error_block">
              <p class="text-sm text-gray-600">{{ notification.text }}</p>
            </section>
          </section>
        </section>
      </Notification>
    </div>
  </div>
</NotificationGroup>
  <!-- <main id = 'app'>
    <errorComponent :error = "error"></errorComponent>
    <section  v-for="error in errors" v-bind:key="error.id">
      <errorComponent :error = 'error'></errorComponent>
    </section>
  </main> -->
  
  <RouterView></RouterView>
  

</template>




<style>


#app {
  font-family: 'Gilroy-Regular', sans-serif;
  font-family: Gilroy;
  /* text-align: center; */
  /* background-color: aqua; */
  height: 100%;
  width: 100%;
}

.error_block{
    position: static;
    top: 80%;
/*  */  
    /* left: 50%; */
    background-color: grey;
    /* opacity: 60%; */
    z-index: 5;
    height: 32px;
    color: white;
    padding: 5px;
    text-align: center;

}

*{
  cursor: default;
}

</style>
