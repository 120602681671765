<script setup>


</script>

<template>
    <RouterView></RouterView>

</template>

<style scoped>

input.aut_input{
    display: flex;
    width: 446px;
    height: 42px;
    padding: 0px 12px 0px 10px;
    justify-content: center;
    align-items: center;
}

</style>