import { createApp, provide, h } from 'vue'
import App from './App.vue'
import router from './router'
import { createPinia } from 'pinia'
import sectionCss from '@/assets/section.css'
import textCss from '@/assets/text.css'
import loaderCss from '@/assets/loader.css'
import buttonCss from './assets/buttons.css'
import Notifications from 'notiwind'
import { DefaultApolloClient } from '@vue/apollo-composable'
const pinia = createPinia();
import { apolloClient } from "@/apollo.config";


const app = createApp({
    setup () {
        provide(DefaultApolloClient, apolloClient)
      },
    
      render: () => h(App),
})

app.use(loaderCss)
app.use(router)
app.use(pinia)
app.use(sectionCss)
app.use(textCss)
app.use(buttonCss)
app.use(Notifications)

app.mount('#app')
