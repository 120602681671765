<script setup>
    import { useClientStore} from '@/stores/client';
    import { storeToRefs } from 'pinia';
    import eSelectBox from '@/components/vue3-ecomponents/select_box.vue';
    import eTextInput from '@/components/vue3-ecomponents/text_input.vue';
    import eLoader from '@/components/vue3-ecomponents/loader-spiner.vue'
    import { StatusFilter } from './macaps/StatusFilter'
    import { onMounted, ref} from 'vue'
    import eMultiSelect from "@/components/vue3-ecomponents/multi_select.vue"
         
    // const {result: resultAdmCompany} = useQuery(AdmCompaniesV2,
    // (() => ({
    //     blocked:false
    // }))
    // );
    // const {resultGetLocalStorage, variablesLocalStorage} = useQuery(AdmCompaniesV2);
    
    const OpenRightBar = ref(false);

    onMounted(() => {
        clientStore.RequestAdmComp()
    })

    const clientStore = useClientStore();

    const {
        load,
        clients,
        current_link,
        status_filter,
        find_filter,
        current_tags,
        current_company,

    } = storeToRefs(clientStore);

    // watch(resultAdmCompany, value => {
    //     clientStore.updateClients(value.AdmCompaniesV2.companies);
    // })

    const copyStorage = async (client) => {

        const rez = await clientStore.copyStorage(client.user.email);
        console.log("https://online.entersight.ru/setToken?token=" +
            JSON.parse(rez.data.data.LocalStorage)?.core?.token);
        await navigator.clipboard.writeText(
            "https://online.entersight.ru/setToken?token=" +
            JSON.parse(rez.data.data.LocalStorage)?.core?.token
          );
        await window.open(
          "https://online.entersight.ru/setToken?token=" +
            JSON.parse(rez.data.data.LocalStorage)?.core?.token
        );
    }

    // watchEffect(() => {
    //     clients.value.map(el => {
    //     console.log(el)
    //     el.user.lastActivity = el.user.lastActivity.replace("-",".").substr(0.10)
    //     el.createdDate = el.createdDate.replace("-",".").substr(0.10)
    // })
    // })


</script>
    
<template>
    <h2 style="
    position: absolute;
    left: 272px;
    ">Клиенты</h2>
    <section 
        aria-placeholder="Поиск"
        style="
        display: flex;
        flex-direction: row;
        position: absolute;
        left: 272px;
        top: 72px;
        height: 32px;
        background-color:#F8F8F9">
        <eSelectBox
            v-model="find_filter"
            :variants="clientStore.getFinderVariants()"
            @change="(current_variant) => {
                    find_filter = current_variant;
                    console.log(find_filter, 'status filter')
                }"
            :input="true"
            :type="'select'"
            :value="find_filter"
            :width="320">
        </eSelectBox>
        <eSelectBox 
            :width="150"
            style="
            margin-left: 16px;"
            @change="(current_variant) => {
                    status_filter = current_variant;
                    console.log(status_filter, 'status filter')
                }"
            :variants="StatusFilter"
            :input="false"
            :type="'select'"
            :value="status_filter"
            v-model="status_filter">
        </eSelectBox>
    </section>
    <section id = 'main'>
        
            
            <!-- //бошка -->
            
        <section style = '
        height: calc(100vh - 150px);
        width: (100vh - 300px);
        overflow-y: auto;
        '>
            <section>
            <!-- //бошка -->
            </section>
            <section style = '
            '>
                <section 
                
                id = 'head' style="
                position: sticky;
                z-index: 0;
                display: grid;
                top: 0px;
                width: 100%;
                background: #FFF;
                height: 48px;

                /* align-content: ; */
                /* padding-left: 5px; */
                grid-template-columns: 60px 250px 250px 200px 200px 200px 200px 200px 200px 240px;">
                    <article class = 'svg_block'
                    style = '
                        height: 100%;
                        width: 100%;
                        /* border: 1px solid black; */
                        border-bottom: 1px solid #E1E1E1;
                        align-content: center;
                        align-items: center;
                        justify-content: center;
                        padding: 0px;
                        border-right: 0px;
                    '>
                        <input type='checkbox'
                        style="
                        height: 16px;
                        width: 16px;
                        border-radius: 2px;
                        ">
                    </article>
                    <article 
                    style = '
                    border-left: 0px;'>
                        Компания
                    </article>
                    <article>
                        Email
                    </article>
                    <article>
                        Телефон
                    </article>
                    <article>
                        Дата регистрации
                    </article>
                    <article>
                        Послед. Актив.
                    </article>
                    <article>
                        Статус
                    </article>
                    <article>
                        Подписка до
                    </article>
                    <article>
                        Тег
                    </article>
                    <article>
                        Чат в месенджере
                    </article>
                </section>
                <section v-if="load"
                style="
                margin-left:auto;
                margin-right: auto;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                justify-items: center;
                height: 400px;
                ">
                    <eLoader>

                    </eLoader>
                </section>
                <section
                style="">
                    <section
                        @click="() => {
                            if (OpenRightBar && current_company == client.id) {
                                OpenRightBar = false;
                            } else {
                                OpenRightBar = true;
                            }
                            current_company = client.id
                            console.log(current_company)
                        }"
                        style="width: calc(100% - 252px);
                        display: grid;
                        text-align: center;
                        /* align-items: center; */
                        min-height: 48px;
                        grid-template-columns: 60px 250px 250px 200px 200px 200px 200px 200px 200px 240px;"
                    v-for="client in clients" :key="client.id">
                    
                    <article class = ''
                    style = '
                        width: 100%;
                        padding: 0px;
                        display: flex;
                        justify-content: center;
                        border-bottom: 1px solid #E1E1E1;
                    '>
                        <input type='checkbox'
                        style = ' style="
                        height: 16px;
                        width: 16px;'>

                    </article>
                    <article class = 'grid_cell'>
                        {{ client.name }}
                    </article>
                    <article class = 'grid_cell'>
                        <span
                        @click="() => {copyStorage(client)}"
                        style="
                        text-align: center;
                        font-family: Gilroy; 
                        border-bottom: 1px solid var(--gray-gray-150, #888);">{{ client.user.email }}</span>
                    </article>
                    <article class = 'grid_cell'>
                        {{ client.user.phone }}
                    </article>
                    <article class = 'grid_cell'>
                        {{ client.createdDate }}
                    </article>
                    <article class = 'grid_cell'>
                        {{ client.user.lastActivity }}
                    </article>
                    <article class = 'grid_cell'>
                        {{ client.status }}
                    </article>
                    <article class = 'grid_cell'>

                    </article>
                    <article class = 'grid_cell'>

                    </article>
                    <article class = 'grid_cell'>
                        
                    </article>
                    </section>
                </section>
                <!-- grid -->
            </section>
        </section>
    </section>
    <Transition
    name="leftbar">
    <section id='right_bar' 
    v-if="OpenRightBar">
        <article>
            <h3>
                Редактирование
            </h3>
        </article>
        <article
        >
            <span>Теги</span>
            <eMultiSelect
            :variants="current_tags"
            :type="multiselect"
            :find="false"
            :width="250"
            v-model="status_filter"
            :value="current_tags">
            </eMultiSelect>
        </article>
        <article
        style="
        margin-top: 4vh;">
            <span>Ссылка на чат в мессенджере</span>
            <eTextInput
            @change="(text) => {
                current_link = text
            }"
            :placehold="'Вставьте ссылку'">

            </eTextInput>
        </article>
    </section>
    </Transition>
</template>



<style>

.leftbar-enter-active,
.leftbar-leave-active {
    transition: all 0.3s ease-in-out;
}

#right_bar {
    height: 100%;
    width: 282px;
    position: absolute;
    right: 0px;
    top: 0px;
    background-color: #FFF;
    padding: 22px 22px 22px 22px;
    transition: all 0.5s
}

#right_bar  .activ{
    transition: all 0.5s;
    right: 0px;
}

::-webkit-scrollbar {
  border-radius: 6px;
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 6px;
  width: 8px;
  height: 8px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 6px;
  height: 64px;
  width: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

#head article {
    color: var(--text-secondary-gray-400, #888);
    font-family: Gilroy;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; 
    height: 100%;
    border: 1px solid var(--gray-gray-150, #E1E1E1);
    /* justify-content: center; */
    padding-left: 10px;
    align-items: center;
    background: #FFF;
    display: flex;
    /* justi */
}

.grid_cell input::before{
    border: 1px solid var(--text-system-blue-400, #00A3FF);
                        background: var(--text-system-blue-400, #00A3FF);
}

.grid_cell{
    border-bottom: 1px solid #E1E1E1;
    display: flex;
    align-items: center;
    justify-content: left;
    align-content: center;
    text-align: left;
    padding-left: 10px;
    /* Primary */
    color: var(--text-primary-gray-700, #313131);
    font-family: Gilroy;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* width: calc( 100%  - 10px); */
    /* border: 1px black solsid; */
}


a.nav{
    color: #888888;
    margin-left: 22px;
}

#main{
    left: 272px;
    position: absolute;
    width: calc(100% - 282px );
    border-radius: 6px;
    border: 1px solid var(--gray-gray-150, #E1E1E1);
    background: #FFF;
    top: 120px;
}


head article{
    color: var(--text-secondary-gray-400, #888);
    border-right: 1px solid #888;
    font-family: Gilroy;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}
</style>