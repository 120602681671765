import { defineStore } from 'pinia'
import { reactive, ref} from 'vue';
// import {useClinetStore} from, ''
import axios from "axios";
// import Buffer from 'buffer'
// import ref from '@ref'

export const useAgentsLogsTableStore = defineStore('agentslogstable', () => {

    const agents= ref([]);

    // const skip= ref(0);
    // const take= ref(1000);

    const logs = reactive([]);
    const agent_filter = ref([]);

    const getAgents = async () => {
      let data = JSON.stringify({
        query: `
        query a{
          AgentSelect(){
            agents{
            id
            names
            }
          }}
        `,
        variables: {
          
        }
      });
      
      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'https://test-api.entersight.ru/graphql',
        headers: { 
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjoiZGRhOGMxM2ItYTAxNi00NDg1LThlZWItNmYxMTA1MWZjOGFlIiwiZW52IjoicHJvZCIsImlhdCI6MTY5NzQ0MzI0NSwiZXhwIjoxNjk5MjQzMjQ1LCJzdWIiOiJkZGE4YzEzYi1hMDE2LTQ0ODUtOGVlYi02ZjExMDUxZmM4YWUifQ.LIwfWXr1Fozax54xaKaiVeKzLjj1mtmVy44IeprrB6Q'
        },
        data : data
      };
      
      const rez = (await axios.request(config)).data;
      console.log(rez);
      agents.value = rez.data.AgentSelect.agents;
    }

    const getLogs = async () => {
      let data = JSON.stringify({
        query: `
        query b{
          AdmAgents{
            list{
              message
              version
              level
              date
              name
            }
            total
          }
        }        
        `,
        variables: {}
      });
      
      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'https://test-api.entersight.ru/graphql',
        headers: { 
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjoiZGRhOGMxM2ItYTAxNi00NDg1LThlZWItNmYxMTA1MWZjOGFlIiwiZW52IjoicHJvZCIsImlhdCI6MTY5NzQ0MzI0NSwiZXhwIjoxNjk5MjQzMjQ1LCJzdWIiOiJkZGE4YzEzYi1hMDE2LTQ0ODUtOGVlYi02ZjExMDUxZmM4YWUifQ.LIwfWXr1Fozax54xaKaiVeKzLjj1mtmVy44IeprrB6Q'
        },
        data : data
      };
      
      logs.value = (await axios.request(config)).data.data.FranchiseSelect.franchise;
    }
    
    return{
        agents,
        logs,
        getAgents,
        getLogs,
        agent_filter
    };

})