<script setup>
    import { useUserStore } from '@/stores/import.store'
import { notify } from 'notiwind';
    import { storeToRefs } from 'pinia'


    const userStore = useUserStore();

    const {
        token, 
        isLogin,
        isError,
        baseAdres,
    } = storeToRefs(userStore);

    const ChengeToken = async () => {
        // userStore.parseToken();

        // if (!change  ) {
        //     rez = await userStore.RequestBranch();
        // } else{
        //     userStore.chengeBaseAdres();
        //     rez = await userStore.RequestBranch();
            
        // }
        // console.log();
        try {
            if (userStore.parseToken()?.env == 'test') {
                baseAdres.value = 'test-api.entersight.ru';
            } else {
                baseAdres.value = 'api.entersight.ru';
            }
        } catch(err) {
            notify({
                text:err,
                })
        }
        await userStore.RequestBranch();
    }

</script>

<template>
    <article 
     style = '
     width: calc(100% - 225px);
     margin-top: 4px;   
     display: flex;
     flex-direction: row;
     justify-content: right;'>
        <button class = 'go' 
        :class='{canGo: isLogin, Error:isError}'
        @click="() => {
            userStore.chengeNavIndex(2);
            if (isLogin) $router.push('/import/storage')
        }"><a>Продолжить</a></button>
    </article>
    <section class="main_meny" id = 'token_component'>
    <h2 
    style = '
    color: var(--text-primary-gray-700, #313131);
    font-family: Gilroy;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;'>Токен клиента</h2>
    <input 
    placeholder="Введите токен"
    id = 'tokenInput'
    v-model='token'
    @change='ChengeToken()' 
    type="text"/>
        

    </section>
</template>

<style>

#beta_art{
    text-align: right;
    margin-left: auto;
    margin-right: 0px;
}

button.go{
    width: 105px;
    border-radius: 2px;
    height: 32px;
    border: 1px solid var(--gray-gray-150, #E1E1E1);
    background: var(--secondary-b-50, #F2F3F6);
    color: white;
    display: flex;
    padding: 0px 12px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: var(--gray-gray-400, #888);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    border-radius: 2px;
}

article.right_button_article{
    text-align: right;
}

input#tokenInput{
    height: 20px;
    width: 90%;
    margin-bottom: 10px;
    padding: 6px 12px 6px 10px;
}

button.go.canGo{
    background-color: dodgerblue;
}

button.go.Error{
    background-color: red;
}
</style>