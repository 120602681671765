<script setup>
    import { useUserStore } from '@/stores/import.store'
import { notify } from 'notiwind';
    import { storeToRefs } from 'pinia'
    import { ref } from 'vue'

    const userStore = useUserStore();

    const load = ref(false);


    const {
        branches,
        current_branch
    } = storeToRefs(userStore);


    const upload_file = async (id) => {
        load.value=true;
        console.log('upload_file'); 
        try {
            current_branch.value = id;
            await userStore.UploadFileToBack();
            // if (rez) {

            // }
        } catch (err) {
            console.log(err);
            notify({
                text: err 
            });
        }
        load.value=false;
    }

    const reload_file = async (id) => {
        current_branch.value = branches.find((el) => {return el.id == id});
    }
</script>

<template>
    <article 
     style = '
     width: calc(100% - 225px);
     margin-top: 4px;
     display: flex;
     flex-direction: row;
     justify-content: right;'>
        <button class = 'go' 
        :class='{canGo: true, Error:false}'
        @click="() => {
            $router.push('/clients')
        }"><a>Завершить</a></button>
    </article>
    <section class = 'main_meny'>
        <h2>Выгрузка</h2>
        <article v-if="!load">
        <section  :key = 'id' v-for = '{name,id,can_upload,isLoad,upload_error} in branches' class="easy_flex_line line">
            
                <a>{{ name }}</a>
                <article v-if="!upload_error && !isLoad">
                    <button class="blue_button" :class = "{can_click:can_upload}" @click="(async (event) => {
                        await upload_file(id);
                    })">Загрузить</button>
                </article>
                <article v-else-if="isLoad">
                    <button class="blue_button exelent" @click="((event) => {
                        reload_file(id);
                    })"
                    >Загруженно</button>
                </article>
                <article v-else>
                    <button class="blue_button error" @click="((event) => {
                        reload_file(id);
                    })">Перезагрузить</button>
                </article>

        </section>
    </article>
    
    <article  class = 'loader' v-else>
            load
    </article>
    </section>
</template>

<style scoped>

.blue_button{
    background-color: grey;
    width: 150px;
    height: 30px;
    color: white;
    border-radius: 2px;
}

.blue_button.can_click{
    border-radius: 2px;
    background: var(--text-system-blue-400, #00A3FF);
    width: 150px;
    height: 30px;
    color: white
}

.blue_button.exelent{
    border-radius: 2px;
    background: #00CB91;
    width: 150px;
    height: 30px;
    color: white
}

.blue_button.error{
    background: var(--text-alert-red-400, #E53835);
    width: 150px;
    height: 30px;
    color: white;
    border-radius: 2px;
}


.easy_flex_line{
    width: 400px;
}

.easy_flex_line a{
    width: 50%;
}

</style> 