<!-- eslint-disable vue/valid-v-model -->
<script setup>


import { useUserStore } from '@/stores/import.store'
    import { storeToRefs } from 'pinia'

    const userStore = useUserStore();

    const {
        branches
    } = storeToRefs(userStore);

</script>


<template>
    <article 
        style = '
        width: calc(100% - 225px);
        margin-top: 4px;
        display: flex;
        flex-direction: row;
        justify-content: right;'>
            <button class = 'go' 
            :class='{canGo: true, Error:false}'
            @click="() => {
                userStore.chengeNavIndex(3);
                $router.push('/import/file')
            }"><a>Продолжить</a></button>
    </article>
    <section  class = 'main_meny'>
        <h2>Места хранения</h2>
        
        <article class="red_block" v-if="branches.reduce((storage_count,branche) => {
            console.log(branche);
            storage_count+=branche.storage.length},storage_count)<= 0"><h2>Ошибка, в энтерсайте не заведенны склады</h2></article>
        <section v-for="{ id,storage} in branches" :key="id">
            <section class = 'store_line' v-for="stor in storage" :key="stor.id">
                <section class = 'mini_meny_box'>
                    <a>Место хранения в Энтерсайт</a>
                    <a class = 'small_text store_name'>{{ stor.name }}</a>
                </section>
                <section class = 'mini_meny_box'>
                    <a>ID</a>
                    <input class = 'id_input small_text' @change= "(event) => {
                        userStore.RequestStore(storage[stor.index].id,event.target.value);
                        storage[stor.index].ext_id = event.target.value
                    }"
                    type="text"
                    v-model='storage[stor.index].ext_id'
                    placeholder="ID"/>
                </section>
            </section>
        </section>
    </section>
</template> 

<style scoped>

a.store_name{
    margin-top: 2px;
    border-radius: 2px;
    /* border: 1px solid #BCBCBC; */
    border: 0px;
    width: 203px;
    height: 29px;
    vertical-align: middle;
    padding-top: 6px;
}

.main_meny{
    margin-top: 8px;
}

input{
    height: 32px;
    background: linear-gradient(0deg, #FFFFFF, #FFFFFF),
    linear-gradient(0deg, #BCBCBC, #BCBCBC);
    margin-top: 2px;
    border-radius: 2px;
    border: 1px solid #BCBCBC

}

input.id_input{
    width: 52px;
}
section.store_line{
        
        display: flex;
        justify-content: strart;
}

</style>