import { defineStore } from 'pinia'
import {ref} from 'vue';
// import {useClinetStore} from, ''
import axios from "axios";
// import Buffer from 'buffer'
// import ref from '@ref'

export const usePlusStore = defineStore('plusStore', () => {

    const files = ref([]);

    const firstFile = ref();
    const secondFile = ref();

    // const base_import_url = 'http://localhost:10010/';
    const base_import_url = 'https://import.entersight.ru/';
    
    const requestPlusFile = async () => {
      console.log("пошёл запрос на соед");

      
      files.value = [firstFile,secondFile]
      const data = new FormData();
      data.append("files",files);
      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${base_import_url}/plus`,
        headers: { 

            'Content-Type': 'multipart/form-data;',
        },
        data : data
      };
      const response = await axios.request(config);

      
      console.log(response.data);

      // if (response.data?.error?.length) {
      // }  

      return response.data;
    }



    return{
        requestPlusFile,
        firstFile,
        secondFile
    };

})