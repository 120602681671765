<script setup>
    // import { Ref, defineProps } from "vue";
    import { useUserStore } from '@/stores/import.store'
    import { storeToRefs } from 'pinia'

    const userStore = useUserStore();

    const {
        navIndex
    } = storeToRefs(userStore);

 
</script>


<template>
    <section id = 'nav_meny'>
        <nav>
            <button class="nav" 
            :class='{"current" : navIndex == 1}'
            @click="
                () => {
                    $router.push('/import/token')
                    userStore.chengeNavIndex(1)
                }">1. Токен</button>
            <button class="nav" 
            :class='{"current" : navIndex == 2}'
            @click="() => {
                $router.push('/import/storage')
                    userStore.chengeNavIndex(2)
                }">2. Сопостовление мест хранения</button>
            <button  class="nav"    
            :class='{"current" : navIndex == 3}'
            @click="() => {
                    $router.push('/import/file')
                    userStore.chengeNavIndex(3)
             }">3. Проверка файла</button>
             <button  class="nav"    
            :class='{"current" : navIndex == 4}'
            @click="() => {
                    $router.push('/import/compaund')
                    userStore.chengeNavIndex(4)
             }">4. Проверка файла компоновки</button>
            <button class="nav" 
            :class='{"current" : navIndex == 5}'
            @click="() => {
                    $router.push('/import/upload')
                    userStore.chengeNavIndex(5)
            }">5. Загрузка на продакшин</button>
        </nav>
    </section>
</template>

<style scoped>

#nav_meny{
    background: #F8F8F9;
    width: calc(100% - 258px - 226px);
    display: flex;
    /* justify-content: start; */
    background-color: none  ;
    flex-direction: column;
    text-align: start;
    margin-left: 258px;
    border-bottom: 2px #888 solid;
}
 
button.nav{

    color: var(--text-secondary-gray-400, #888);
    text-align: center;
    font-family: Gilroy;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    height: 30px;
    font-size: 18px;
    background: #F8F8F9;
    border: 0px;
}

#b{
    position: absolute;
    top: 10px;
    left: 10px;
    width: calc(100% - 258px - 272px);
}

button.nav.current{
    color: dodgerblue;
    border-bottom: 2px solid dodgerblue;
    z-index: 3;
}
</style>
