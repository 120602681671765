<script setup>


</script>

<template>
    <section
    style = "
    position: absolute;
    display: flex;
    flex-direction: row;
    height: calc(100% - 129px) ;
    width: 100%;
    padding:   105px 105px  105px 24px;
    font-weight: 500; font-size: 32px
    ">
        <section style="
        display: flex;
        flex-direction: column;
        width: 50%;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
        
        ">
            <section style="
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-content: center;
            align-items: center;
            width: 50%;
            ">

            
                <img src="@/assets/admin-pannel.png" alt="">
                <h3>Вход</h3>
                <section style="
                width: 100%;">
                    <article style="
                    margin-top: 48px;">
                        <p>Электроннная почта</p>
                        <input type="email" name="login" id="login"
                        class = 'aut_input'
                        style="
                        height: 42px;
                        width: 100%;
                        ">
                    </article>
                    <article  style="
                    margin-top: 48px;">
                        <p>Пароль</p>
                        <input type="password" name="password" id="password"
                        class = 'aut_input'
                        style="
                        height: 42px;
                        width: 100%;
                        margin-top: 0px">
                    </article>
                    <p>Забыл пароль, ну лох, что сказать</p>

                    <button class = 'base_button'
                    @click="
                    () => {
                        
                    }
                    "
                    style="
                    width: 100%;
                    height: 42px;
                    padding: 0px 22px 0px 22px;
                    margin-top: 48px;">
                        <a>
                            Войти
                        </a>
                    </button>

                </section>
                <p>Ещё нет аккаунта, ну и не надо тебе его</p>
            </section>

            <p
            style="
           ">© 2021-2022  ООО «Иволга-С»</p>
        </section>
        <section style="
        display: flex;
        flex-direction: column;
        width: 50%;
        height: 100%;">
        <img src="@/assets/web-dev-colour.png">
        <a>Добро пожаловать в админ-панель</a>
        </section>
    </section>
</template>

<style scoped>

input.aut_input{
    display: flex;
    width: 446px;
    height: 42px;
    padding: 0px 12px 0px 10px;
    justify-content: center;
    align-items: center;
}

</style>