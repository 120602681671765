
<script setup>
    import { defineEmits, defineProps, ref} from "vue"

    const props =  defineProps({
        variants: Array,
        height: Number,
        width: Number,
        size: String,
        input: Boolean,
        ret_value: String,
        type:String,
        value:Boolean,
    })

    // defineModel

    // if (!props.type) {
    //     props.type='defalt';
    // }

    // if ()

    const open = ref(false);

    // const vars = [{text:'ыыы'}];
    const emit = defineEmits(({
        input:({value}) => {
            return value || '0000';    
        },
        change:({value}) => {
            return value;
        }
    }));

    //console.log('пропсы в сулект бокс');

    const timeoutIndex = ref();


    const input = (event) => {
        clearTimeout(timeoutIndex.value || '');
        timeoutIndex.value = setTimeout(() => {
            emit('input',event.target.value);
        }, 250);
    }

    // const getHight = () => {
    //                 if (props.variants?.length * 48 < 200) {
    //                     return (props.variants?.length * 48) + 'px'
    //                 } 
    //                 return 300 + 'px';
    //             }
    // clearTimeout()
</script>

<template>
    <main @click = "() => {
            if (open) {
                open = false;
            } else {
                open = true
            }
            console.log('click to open ' + open)}">
        <section 
        :style="{ 
            width: props.width+ 'px',
        }"

        id="ua" 
       >
        <article
        :style='{
                width: -1 * props.width + "px",
                top:"0px"
            }'
                style="
            position:relative;
        ">
            <svg 
            v-if="props.input"
            style="
                width: 12px;
                height: 12px;
                "
            xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                    <path d="M11.8175 10.9301L8.85934 7.9597C9.61995 7.08675 10.0367 5.98841 10.0367 4.845C10.0367 2.17351 7.78542 0 5.01834 0C2.25127 0 0 2.17351 0 4.845C0 7.51648 2.25127 9.68999 5.01834 9.68999C6.05714 9.68999 7.04707 9.3875 7.89342 8.81326L10.8741 11.8062C10.9987 11.9311 11.1663 12 11.3458 12C11.5158 12 11.677 11.9374 11.7994 11.8237C12.0595 11.5821 12.0678 11.1814 11.8175 10.9301ZM5.01834 1.26391C7.06365 1.26391 8.72756 2.87034 8.72756 4.845C8.72756 6.81965 7.06365 8.42608 5.01834 8.42608C2.97304 8.42608 1.30913 6.81965 1.30913 4.845C1.30913 2.87034 2.97304 1.26391 5.01834 1.26391Z" fill="#BCBCBC"/>
            </svg>     
        </article>

        <!-- inter text -->
        <div>

            <input
                v-if="props.input"
                type="text"
                placeholder="Поиск"
                :value="variants?.find((el) => {if (el?.value == value) return el})?.text || undefined"
                @change=" (event) => {
                    open = true;
                    input(event);
                }"
                :style="{
                    width: props.width  - 30 + 'px',
                }"
                style="
                    z-index:3;
                    border:0px;
                    height:calc(100% - 16px);
                    background-color:white;
                "/>
            <span v-else
            style="
            color: var(--text-primary-gray-700, #313131);
            font-family: Gilroy;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;"
            :style="{
                    width: props.width  - 30 + 'px',
                }"
            >
                {{ variants?.find((el) => {if (el.value == value) return el})?.text || 'Вариантов нет' }}
            </span>
        </div>
        
        
        
        
        </section>
        <!-- arrow  -->
        <article
        :style="{  
                right: '-' + ( props.width + 10 ) +  'px',
            }"
        style="
            position:absolute;
            z-index: 4;
            top:-24px;
            width: 12px;
            transition: all 0.5s;">
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none"
                v-if="props.type=='select'"
                
                :class="{
                    rot_arrow:!open,
                    no_rot_arrow:open
                }">
                <path d="M0.517962 3.09C0.234973 3.36305 0.235053 3.81641 0.518139 4.08936L5.62935 9.01762C5.8362 9.21707 6.1638 9.21707 6.37065 9.01762L11.4819 4.08936C11.7649 3.81641 11.765 3.36305 11.482 3.09C11.2131 2.83052 10.7871 2.83046 10.5181 3.08986L6 7.44682L1.48193 3.08986C1.21294 2.83046 0.786881 2.83052 0.517962 3.09Z" fill="#313131"/>
            </svg>    
        </article>  
        <section
        :style='
            {
                width: props.width + 34 + "px"
            }'>
            <article v-if="open"
            id="dropdown"
            :style='
            { 
                width: props.width + 34 + "px",
            }'

            :class="{
                open_variants:open  
            }">
                    <a class="var_article" v-for="variant in props.variants" v-bind:key = "variant.code" @click = "() => {
                                    let current_variant = variant.value;
                                    open=false;
                                    emit('change',current_variant);
                    }">
                        {{ variant?.text}}
                </a>
            </article>
        </section>
    </main>
</template>

<style scoped>
#ua{
    border-radius: 2px;
    border: 1px solid var(--text-placeholder-gray-300, #BCBCBC);
    background: var(--gray-white, #FFF);
    display: flex;
    padding: 8px 16px 8px 12px;
    align-items: center;
    gap: 8px;
    height: 12px;
}

#ua input[type=text]{
    appearance: none;
	border: none;
	outline: none;
	border-bottom: none;
    margin-left:16px
}

.var_article{
    display: flex;
    align-items: center;
    height: 16px;
    gap: 16px;
    width: calc(100% - 24px);
    padding: 12px 12px 12px 12px;
}

main{
    position: relative;
}

#dropdown{
    color: var(--text-primary-gray-700, #313131);
                font-family: Gilroy;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
                transition: all 1s;
                position: relative;
                max-height: 250px;

                overflow-y:scroll;
                z-index:7;
                border-radius: 4px;
                background: #FFF;
                display: flex;
                top: -100px;
                flex-direction: column;
                align-items: flex-start;
                padding:0px;
                margin-top: 8px;
                box-shadow: 0px 5px 5px 5px rgba(0, 0, 0, 0.10);    
}
.var_article:hover{
    background-color:grey;
    -webkit-transition: background-color 1000ms linear;
    -ms-transition: background-color 1000ms linear;
    transition: background-color 1000ms linear;
}

.rot_arrow{
    transform: rotate(90deg);
    transition: all 0.5s;
}

.open_variants{
    transform:scale(1) translateY(80px);
    transition: all 0.5s;
    padding: 0px;
}

.no_rot_arrow{
    transform: rotate();
    transition: all 0.5s;
}
/* ===== Scrollbar CSS ===== */
  /* Firefox */
  * {
    scrollbar-width: auto;
    scrollbar-color: #241717 #ffffff;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 16px;
  }

  *::-webkit-scrollbar-track {
    background: #ffffff;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #5c5c5c;
    border-radius: 10px;
    border: 3px solid #ffffff;
  }
</style>
