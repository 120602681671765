import { ApolloClient, InMemoryCache} from "@apollo/client/core";
import { HttpLink } from "apollo-link-http";
import { setContext } from "apollo-link-context";

const authLink = setContext((request, previousContext) => {
  return {
    headers: {
      ...previousContext.headers,
      Authorization: "Bearer " + localStorage.getItem("admin_token"),
    },
  };
});
//
// const httpLink = createHttpLink({
//     uri: process.env.VUE_APP_GRAPHQL_ENDPOINT,
// })

const headerLink = new HttpLink({ uri: process.env.VUE_APP_GRAPHQL_ENDPOINT });

// const errorLink = onError(({ graphQLErrors, networkError }) => {
//   if (graphQLErrors)
//     graphQLErrors.map(({ message, locations, path }) =>
//       console.log(
//         `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
//       )
//     )
//   if (networkError) console.log(`[Network error]: ${networkError}`)
// })

// authLink.concat(errorLink)

export const apolloClient = new ApolloClient({
  uri: process.env.API_URL,
  link: authLink.concat(headerLink),
  // link: authLink.concat(httpLink),
  // link: httpLink,
  cache: new InMemoryCache({ addTypename: false }),
});
