
<script setup>
    import { defineProps, ref} from "vue"
    import eCheckBox from '@/components/vue3-ecomponents/checkbox_man.vue'

    const props =  defineProps({
        variants: Array,
        height: Number,
        width: Number,
        size: String,
        value:String,
    })

    // defineModel

    // if (!props.type) {
    //     props.type='defalt';
    // }

    // if ()

    const open = ref(false);

    // const vars = [{text:'ыыы'}];
    // const emit = defineEmits(({
    //     input:({value}) => {
    //         return value || '0000';    
    //     },
    //     change:({value}) => {
    //         return value;
    //     }
    // }));

    // clearTimeout()
</script>

<template>
    <main >
        <section 
        @click = "() => {
            if (open) {
                open = false;
            } else {
                open = true
            }
            console.log('click to open ' + open)
        }"
        :style="{ 
            width: props.width+ 'px',    
        }"

        id="ua" 
       >

        <!-- inter text -->
        <div
        style="
        display: flex;
        flex-wrap: wrap;
         /* 185.714% */">
            <article 
            style="color: var(--text-system-blue-400, #00A3FF);
            font-family: Gilroy;
            height: 20px;
            font-size: 14px;
            padding: 0px 6px 2px 6px;
            border-radius: 3px;
            background: var(--blue-100, #DDF3FF);
            font-style: normal;
            line-height: 26px;
            font-weight: 400;" v-for="cur_var in value" :key="cur_var.value">
                {{ cur_var.text  }}
            </article>
        </div>
        
        
        
        
        </section>
        <!-- arrow  -->
        <article
        :style="{  
                right: '-' + ( props.width + 10 ) +  'px',
            }"
        style="
        
            position:relative;
            z-index: 4;
            top:-24px;
            width: 12px;
            transition: all 0.5s;">
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none"
                v-if="props.type=='select'"
                
                :class="{
                    rot_arrow:!open,
                    no_rot_arrow:open
                }">
                <path d="M0.517962 3.09C0.234973 3.36305 0.235053 3.81641 0.518139 4.08936L5.62935 9.01762C5.8362 9.21707 6.1638 9.21707 6.37065 9.01762L11.4819 4.08936C11.7649 3.81641 11.765 3.36305 11.482 3.09C11.2131 2.83052 10.7871 2.83046 10.5181 3.08986L6 7.44682L1.48193 3.08986C1.21294 2.83046 0.786881 2.83052 0.517962 3.09Z" fill="#313131"/>
            </svg>    
        </article>  
        <section
        :style='
            {
                width: props.width + 34 + "px"
            }'>
            <article v-if="open"
            :style='
            { 
                width: props.width + 34 + "px",
            }'

            :class="{
                open_variants:open  
            }"
            style="
            color: var(--text-primary-gray-700, #313131);
            font-family: Gilroy;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px; /* 171.429% */
            margin-top: -74px;
            border-radius: 4px;
            padding: 12px;
            border: 1px solid var(--gray-gray-100, #E2E2E2);
            background: #FFF;
            box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.06);">
                    <div class="var_article" v-for="variant in props.variants" v-bind:key = "variant.code">
                        <eCheckBox></eCheckBox>
                        <a>{{ variant?.text}}</a>
                </div>
            </article>
        </section>
    </main>
</template>

<style scoped>
#ua{
    
    border-radius: 2px;
    border: 1px solid var(--text-placeholder-gray-300, #BCBCBC);
    background: var(--gray-white, #FFF);
    display: flex;
    padding: 8px 16px 8px 12px;
    align-items: center;
    gap: 8px;
    height: 12px;
}

#ua input[type=text]{
    appearance: none;
	border: none;
	outline: none;
	border-bottom: none;
    margin-left:16px
}

.var_article{
    display: flex;
    height: 32px;
    padding: 6px 12px;
    align-items: center;
    gap: 16px;
    width: 85%;
}


/* .var_article:hover{
    background-color:grey;
    -webkit-transition: background-color 1000ms linear;
    -ms-transition: background-color 1000ms linear;
    transition: background-color 1000ms linear;
} */

.rot_arrow{
    transform: rotate(90deg);
    transition: all 0.5s;
}

.open_variants{
    transform:scale(1) translateY(80px);
    transition: all 0.5s;
    padding: 0px;
}

.no_rot_arrow{
    transform: rotate();
    transition: all 0.5s;
}
/* ===== Scrollbar CSS ===== */
  /* Firefox */
  * {
    scrollbar-width: auto;
    scrollbar-color: #241717 #ffffff;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 12px;
  }

  *::-webkit-scrollbar-track {
    background: #ffffff;
    height: 12px;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #5c5c5c;
    border-radius: 10px;
    border: 3px solid #ffffff;
    width: 12px;
  }
</style>
