<script setup>
    // import { Ref, defineProps } from "vue";
    import { useUserStore } from '@/stores/import.store'
    import { storeToRefs } from 'pinia'

    const userStore = useUserStore();

    const {
        branches,
        current_branch,
    } = storeToRefs(userStore);
    
</script>


<template>
    <section id = 'nav_meny'>
        <section v-for="branche in branches" :key="branche.id"  @click="current_branch=branche.id"> 
            <article class = 'nav_block' :class="{current:current_branch == branche.id, error: branche.errors_count > 0}">
                {{ branche.name }}
                <article v-if='branche.errors_count > 0' id = 'error_label' :class='{hid:(branche.errors_count<=0 )}'>
                    {{ branche.errors_count }}
                </article>
            </article>
        </section>
    </section>
    
</template>

<style>

#nav_meny{
    display: flex;
    align-items: flex-start;
    gap: 16px;
    flex-direction: row;
}
 
button.nav{
    text-align: center;
    height: 30px;
    font-size: 18px;
    border-bottom: 2px solid grey;
}

#b{
    position: absolute;
    top: 10px;
    left: 10px;
}

article.nav_block.current{
    background-color: dodgerblue;
    /* border-bottom: 2px solid dodgerblue; */
}

article.nav_block.error{
    color: white;
    background: var(--text-alert-red-400, #E53835);
}


#nav_meny{
    background: #F8F8F9;
    width: calc(100% - 258px - 226px);
    display: flex;
    /* justify-content: start; */
    background-color: none  ;
    flex-direction: row;
    text-align: start;
    margin-left: 258px;
}

article.nav_block{
    display: flex;
    height: 32px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border-radius: 100px;
    border: 1px solid var(--gray-gray-150, #E1E1E1);
    background: var(--gray-white, #FFF);
    margin: 5px;
    text-align: center;
}

/* .nav_block.current{
    background: var(--blue-b-400, #00A3FF);
    font-family: Gilroy;
    color: white;
} */

#error_label{
    display: flex;
    padding: 0px 5px;
    justify-content: center;
    align-items: center;
    border-radius: 144px;
    background: var(--text-alert-red-400, #E53835);
    font-family: Gilroy;
    color: white;

}

.nav_block.error #error_label{
    color: black;
    background-color: white;
}

#error_label.hid{
    visibility: hidden;
    opacity: 0%;
}

</style>
