import { defineStore } from 'pinia'
import { reactive, ref} from 'vue';
// import axios from "axios";
// import Buffer from 'buffer'

// import ref from '@ref'
export const useRootStore = defineStore('rootStore', () => {

    const user = reactive([]);
    const isLogin = ref(false)

    if (localStorage.getItem('admin_token')) {
        isLogin.value = true;
    }

    const Login = (data) =>  {
        console.log(data)
        localStorage['admin_token'] =  data.Login.token;
        user.value = data.Login.user;
        isLogin.value = true;
        console.log(localStorage.getItem('admin_token'));
    }

    return {
        Login,
        isLogin,
        user
    }

})