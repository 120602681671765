import SwitchView from './SwitchView/SwitchView.vue'
import AuthorizationView from './AuthorizationView/AuthorizationView.vue'
import RegistrationView from './RegistrationView/RegistrationView.vue'
import RestoreView from './RestoreView/RestoreView.vue'


const AutRoutes = [
    {
      path:'/auth/switch',
      name:'switch',
      component: SwitchView
    },
    {
      path:'/auth/auth',
      name:'auth',
      component: AuthorizationView, 
    },
    {
      path:'/auth/reg',     
      name:'reg',
      component: RegistrationView,
    },
    {
      path:'/auth/restore',
      name:'restore',
      component: RestoreView,
    }
  ]




export default AutRoutes
