import axios from 'axios';
import { defineStore } from 'pinia'
import { ref} from 'vue';
import {AdmCompaniesV2} from '@/stores/gql'


export const useClientStore = defineStore('clientStore', () => {

    const block_filter = ref(false);
    console.log(block_filter.value)
    const status_filter = ref('all');
    const find_filter = ref('');
    const clients = ref([]);
    const current_company = ref("");
    const current_link = ref("");
    const load = ref(true)
    const current_tags = ref([{value: '1111', text:"Metro"}]);

    const RequestAdmComp = async () => {
      load.value = true;
      const data = JSON.stringify(
        {
          query:AdmCompaniesV2,
          variables:{
            blocked:false
          }
        })

      const config = {
          method: 'post',
          maxBodyLengith: Infinity,
          url: process.env.VUE_APP_GRAPHQL_ENDPOINT,
          headers: { 
              'Authorization': 'Bearer ' + localStorage.getItem("admin_token"),
              'Content-Type': 'application/json'
          },
          data : data
        };

        const rez = await axios.request(config);
        // console.log(rez.data)
        clients.value = rez.data.data.AdmCompaniesV2.companies.map(el => {
              // console.log(el)
              try {
                el.user.lastActivity = (el.user?.lastActivity.replace("-",".").replace("-",".").substr(0.10) + " " + el.user?.lastActivity.substr(11.5)) || "";
                el.createdDate = el?.createdDate.replace("-",".").replace("-",".").substr(0.10)
              } catch (err) {
                console.log(err)
              }
              return el
          });
      load.value = false;
    }

    const getFinderVariants = () => {
      return [{
        text:"Все компании",
        value:"all"
      }].concat(clients?.value?.map(el => {
        // console.log(clients)
        return {
          text:el.name,
          value:el.id
        }
      }))
    }

    const copyStorage = async (email) => {
        try {
          console.log(email)
          let data = JSON.stringify({
            query: `query LocalStorage{
                        LocalStorage(email: "${email}", phone: "${email}")
                      }`,
            variables: {
            }
          });

          // console.log(data)

          const config = {
            method: 'post',
            maxBodyLengith: Infinity,
            url: process.env.VUE_APP_GRAPHQL_ENDPOINT,
            headers: { 
                'Authorization': 'Bearer ' + localStorage.getItem("admin_token"),
                'Content-Type': 'application/json'
            },
            data : data
          };

          const rez = await axios.request(config);
          console.log(rez.data)
          return rez;
        } catch (e) {
          console.log(e);
        }
      }

    const updateClients = (cl) => {
      console.log(cl);
      clients.value = cl;

    }

    // const ConvertClients = async () => {
    //   clients.value.map(el => {
    //     console.log(el)
    //     el.user.lastActivity = el.user.lastActivity.replace("-",".").substr(0.10)
    //     el.createdDate = el.createdDate.replace("-",".").substr(0.10)
    // })
    // }

    return{
      updateClients,
      current_company,
      copyStorage,
      status_filter,
      load,
      find_filter,
      current_tags,
      current_link,
      clients,
      getFinderVariants,
      RequestAdmComp,
    };
})