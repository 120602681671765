<script setup>

</script>

<template>
        <h1>Главная</h1>
        
</template>



<style>

</style>