<script setup>
    import {  defineProps, defineEmits } from "vue";

    let current_variant = '' ;
    const props =  defineProps({
        variants: Array,
    })
    // const vars = [{text:'ыыы'}];
    const emit = defineEmits(({
        change:({value}) => {
            return value || '0000';    
        }
    }));
    console.log('пропсы в сулект бокс')

    console.log(props.variants)

    // const getVar = () => {
    //     return props.variants.find((var) => {var.text == current_variant=""}).code;
    // }
</script>


<template>

    <section id="ua" @click = "(event) => {
        if (open) {
            open=false;
        } else {
            open = true
        }
    }">
                <!-- <section id="ua" @change="(event) => {console.log(current_variant);
                emit('change',current_variant)}">
                    <option v-for="variant in variants" :key='variant.code' v-bind:value="variant">
                        {{ variant.text || variant }}
                    </option>
                </select> -->
                <section>
                    <article>

                    </article>
                    <article v-if="open">
                        <article v-for="variant in variants" v-bind:key = "variant.code" @click = "(event) => {
                            current_variant = variant;
                            emit('change',current_variant);
                        }">
                        {{ variant.text || variant }}
                        </article>
                    </article>
                </section>
    </section>
</template>

<style scoped>
#ua{
    width: 150px;
    height: 32px;
    border: 1px solid black;
}
</style>
