import { defineStore } from 'pinia'
import { ref, reactive, computed} from 'vue';
import axios from "axios";
import { useGlobalErrorStore } from '@/stores/globalerror'
import { notify } from 'notiwind';
import {Buffer} from 'buffer'


// import ref from '@ref'
export const useUserStore = defineStore('userStore', () => {
    const globalErrorStore = useGlobalErrorStore();

    // const base_import_url = 'http://localhost:10010/';
    const base_import_url = 'https://import.entersight.ru/';

    const isError = ref(false);
    const baseAdres = ref('api.entersight.ru')
    const IsBetaAdres = ref(false)
    const navIndex = ref(1);
    const count = ref(0)
    const token = ref("");
    const error_filter = ref('')
    const set_double_barcode = ref(false);
    const set_double_plu = ref(false);
    const set_bad_remain = ref(false);
    const change_bad_remainder = ref(false);
    const current_ychetka = ref('');

    const getSettings = () => {
      console.log("get settings")
      return {
        type:current_ychetka.value || "",
        del_d_barcode: set_double_barcode.value,
        del_p_barcode: set_double_plu.value,
        del_bad_remainder: set_bad_remain.value,
        change_null_remainder: change_bad_remainder.value,
      }
    }

    const branches = reactive([
      {
        name:"branch",
        id:"1",
        storage:[
          {name:"store",id:'1',ext_id:""}
        ],
        file:false, 
        products:[{NAME:"prod",ARTICUL:"01",errors:[{text:"Ты лох"}],},{NAME:"prod2",ARTICUL:"0002",errors:[{text:"Задвоение штрихкода 24000000000234",},{text:"пустое поле QUANTITY"}],},],
        can_upload:false,
      } 
    ]);

    const isLogin = ref(true);
    isLogin.value=false;

    const pagination = reactive({start:0,end:0});

    error_filter.value='0000';

    baseAdres.value = 'api.entersight.ru';

    const getBranch = (branch_id) => {
      return branches.find((branch) => {
        if (branch.id == branch_id) {
          return branch
        }
      })
    };

    const chengeBaseAdres = () => {
      if (baseAdres.value.includes("test") && baseAdres.value!='') {
        IsBetaAdres.value = false;
        baseAdres.value='api.entersight.ru';
      } else {
        IsBetaAdres.value=true;
        baseAdres.value='test-api.entersight.ru';
      }
      console.log(baseAdres.value);
    }
    const getCurrentBranch = computed(() => {
      return branches.find((branch) => {
        if (branch.id == current_branch.value) {
          return branch
        }
      })
    })

    const GetBranchErrorsCount = () =>  {
      let count = 0;
      const br = getCurrentBranch.value
      br?.products?.map((pr) => {
          count+=pr.errors.length;
      })
      console.log('GetBranchErrorsCount ' + count + ' ' + current_branch.value);

      return count;
    }
    const GetBranchFilteredErrorsCount= () =>  {
      let count;
      const br = getCurrentBranch;
      br.products?.map((pr) => {
          count+=pr?.errors.length
      })
      return count;
    }

    const current_branch = ref("");

    const chengeNavIndex = (index) => {
      navIndex.value = index;
      // console.log(navIndex.value);
    }

    const RequestSaveBase = async (id) => {
      console.log(token.value)
      console.log("пошёл запрос на сохрание "+ token.value);
      // console.log(getFile);
      let data = new FormData();
      data.append('file', getFile.value);

      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${base_import_url}validateproduct/${id}`,
        headers: { 
            'Authorization': 'Bearer ' + token.value, 
            'Content-Type': 'multipart/form-data;',
        },
        params: {
          settings:getSettings()
        },
        data : data
      };  

      console.log(config);
      const response = await axios.request(config);

      console.log(response.data);
      return response.data;
    }

    const RequestUplaodDataClassIf = async () => {
      console.log("пошёл запрос на выгрузка"+current_branch.value);
      let data = new FormData();
      data.append('file', getFile.value);
      let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `https://${baseAdres.value}/files/import/smarket/classif/${current_branch.value} `,
      headers: { 
          'Authorization': 'Bearer ' + token.value, 
          'Content-Type': 'multipart/form-data;',
      },
      data : data
      };

      console.log(config);

      const response = await axios.request(config);
      console.log(response.data);

      return response
    }

    const RequestUplaodDataRemainderInv = async () => {
      console.log("пошёл запрос remainder " + current_branch.value);
      // console.log(getFile);
      let data = new FormData();
      data.append('file', getFile.value);
      let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `https://${baseAdres.value}/files/import/smarket/remainders/${current_branch.value}?doc_type=inc`,
      headers: { 
          'Authorization': 'Bearer ' + token.value, 
          'Content-Type': 'multipart/form-data;',
      },
      data : data
      };

      const response = await axios.request(config);

      if (response.data?.error?.length) {
        response.data.error.map((err) => {globalErrorStore.PushError(err)})
      }
      console.log(response.data);
    }

    const RequestCompaundErrors = async () => {
            console.log("пошёл запрос на ошибки"+current_branch.value);
            // console.log(getFile);
            
            let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${base_import_url}validateproduct/${current_branch.value}?code=${error_filter.value.code || error_filter.value}`,
            headers: { 
              'Authorization': 'Bearer ' + token.value, 
              'Content-Type': 'multipart/form-data;',
            },
            query: {
              code:error_filter.value,
              pagination: JSON.stringify(pagination.value),
            }
            };

            const response = await axios.request(config);
          return response
        }

    const RequestCompaundSaveBase = async () => {
      console.log("пошёл запрос на сохрание" + current_branch.value);
      // console.log(getFile);
      let data = new FormData();
      data.append('file', getFile.value);
      let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${base_import_url}/validateproduct/${current_branch.value}`,
      headers: { 
          'Authorization': 'Bearer ' + token.value, 
          'Content-Type': 'multipart/form-data;',
      },
      data : data
      };

      const response = await axios.request(config);
     
      await chengeBranch(response.data.products,response.data.err_count)
    }

    const RequestErrors = async (id) => {
            console.log("пошёл запрос на ошибки " + current_branch.value);
            // console.log(getFile);
            
            let config = {
              method: 'get',
              maxBodyLength: Infinity,
              url: `${base_import_url}validateproduct/${id}?code=${error_filter.value.code || error_filter.value}`,
              headers: { 
                  'Authorization': 'Bearer ' + token.value, 
                  'Content-Type': 'multipart/form-data;',
              },
            };

            const response = await axios.request(config);

            console.log(response.data);
          await chengeBranch(response.data.products,response.data.err_count)
          console.log(response.data.products?.length);
          if (response.data.products?.length <= 0) {
            branches.map((branch) => {
              console.log(current_branch)
              if (branch.id == current_branch.value)
              { 
                branch.can_upload = true;
                console.log(branch)
              }
            })
          }
          //  branch.storages
         

        }

      const RequestFixFile = async (id) => {
        
          console.log("пошёл запрос на починку " + current_branch.value);
          // console.log(getFile);
          
          let config = {
            method: 'put',
            maxBodyLength: Infinity,
            url: `${base_import_url}validateproduct/${id}`,
            headers: { 
                'Authorization': 'Bearer ' + token.value, 
                'Content-Type': 'multipart/form-data;',
            },
            params: getSettings(),
          };
  
          const response = await axios.request(config);
          console.log(response.data)
          return JSON.stringify(response.data);

      }

    const RequestBranch = async () => {
      // console.log(token.value);
          let data = JSON.stringify({
          query: `query {
            Branches{
                id,
                name,
                storage {
                  id,
                  name,
                  ext_id,
                }
            }   
        }`,
          variables: {}
        });
  
        let config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: `https://${baseAdres.value}/graphql`,
          headers: { 
            'Content-Type': 'application/json', 
            'Authorization': 'Bearer ' + token.value,
          },
          data : data
        };
        // console.log(config);
        
        let resp = await axios.request(config);
        // console.log(resp);
        // console.log(resp?.data?.errors?.find((error) => {if (error.message == 'Unauthorized') return error}));
        // console.log(resp?.data?.errors?.length);
        if (resp.status == 200 && !resp?.data?.errors?.find((error) => {if (error.message == 'Unauthorized') return error})) {
            // console.log(resp.data);
            let store_indexer = 0;
            branches.length = 0;
            (resp.data.data?.Branches?.map(async (branch) => {
                let returnBranch = branch;
                returnBranch.can_upload=false;
                returnBranch.file="";
                returnBranch.compaund_file='';
                returnBranch.products=[];
                returnBranch.errors_count = 0;
                
                returnBranch.isLoad = await checkIsLoaded(branch.id);
                console.log(returnBranch.isLoad);
                returnBranch.upload_status = false;
                let lesstimeStorage = [];
                // console.log(store_indexer )
                // returnBranch.storage=[];
                branch.storage.map( (store) => {
                  if(store) {
                     console.log(store);
                    let newstore = {
                      index:store_indexer,
                      name:store.name || '',
                      ext_id:store.ext_id || '',
                      id:store.id || ''
                    };
                    lesstimeStorage.push(newstore);
                    store_indexer++;
                  }
                }) || [];
                returnBranch.storage = lesstimeStorage; 
                // console.log(returnBranch);
                branches.push(returnBranch);
                store_indexer=0;
                // console.log(branch.id);
            }))
            isError.value=false;
            isLogin.value=true
            changeCanUpload()
            // chengeIsLogin();
            // console.log(resp.data.data.Login.token)
            return resp.status
        } else if (resp.status==200 && resp?.data?.errors.find((error) => {if (error.message == 'Unauthorized') return error} ) && token.value!='') {
          console.log('Un');
          isError.value=true;
          isLogin.value=false;
          changeCanUpload()
          // chengeIsLogin();
          return resp.status
        } else {
          console.log('also error ');
          isLogin.value=false;  
          isError.value=false;
          changeCanUpload()
          // chengeIsLogin(); 
          return resp.status
        }
       
  
    }

    const UploadFileToBack = async () => {
      let isFirstRequest = true;
      branches.forEach((branch) => {
        if (branch.isLoad) isFirstRequest = false;
      });
      // console.log(branch);
      if (isFirstRequest){
        try {
          await RequestUplaodDataClassIf();
          branches.map( (branch) => {
            console.log("file in FIleToBack " + JSON.stringify(branch)); 
            if (branch.id == current_branch.value){ branch.isLoad = true
            }
          });
          return true;
        } catch(err) {
          notify({
            group:'foo',
            text:err,
          }, 7000);
          return false;
        }
      } else {
        try {
          await RequestUplaodDataRemainderInv();
          branches.map( (branch) => {
            console.log("file in FIleToBack " + JSON.stringify(branch)); 
            if (branch.id == current_branch.value){ branch.isLoad = true
            }
          });
          return true;
        } catch(err) {
          notify({
            group:'foo',
            text:err,
          });
          return false;
        }
      }
      // return false;


    }

    const RequestLogin = async (login,password) =>  {
          let data = JSON.stringify({
            query: `mutation GetToken($email : String, $pass : String!){
              Login(input:{email:$email,password:$pass}){
                  token,user{branches{name,id}}   
              }
          }`,
            variables: {"email":login,"pass":password}
          });
  
          let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `https://${baseAdres.value}/graphql`,
            headers: { 
              'Content-Type': 'application/json', 
              // 'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjoiZTI5YWQ1ZTMtN2E0Ni00Mjg0LWJhYTktNDQxMWZlNGVkNjRlIiwiZW52IjoicHJvZCIsImlhdCI6MTY4NDE1ODA0NSwiZXhwIjoxNjg1OTU4MDQ1LCJzdWIiOiJlMjlhZDVlMy03YTQ2LTQyODQtYmFhOS00NDExZmU0ZWQ2NGUifQ.LPLKcb_mdmL-Mqbvl9PSOUimTzFGeP_JYnNqdzqzhYE'
            },
            data : data
          };
  
  
          let resp = await axios.request(config);
  
          if (resp.status == 200 ) {
              console.log(resp.data);
              (resp.data.data?.Login?.user.branches.map((branch) => {
                  branches.value.push(branch);
                  console.log(branch.id);
              }))
  
              // console.log(resp.data.data.Login.token)
          } else {
            console.log("ups");
            alert("неправильный логин или пароль" + resp.status + ' \n' + resp?.errors)
          }
    }

   const getBranchProducts = computed(() => {
    //  console.log(branches.find({id:current_branch.value}))
    // console.log(branches.find((branch) => { if (branch.id == current_branch.value) return branch }));
      return branches.find((branch) => { if (branch.id == current_branch.value) return branch }).products
    })

    const changeCanUpload = () => {
      
      branches.map((branch) => {
        // console.log(branch);
        branch.storage.map((storage) => {
          if(!(storage?.exten_id!='')) {
            branch.can_upload = false;
          }
        })

        if(branch.file!='') {
          // console.log(branch.file);
          branch.can_upload=true;
        }

        // console.log(branch.products);
        branch?.products?.map((product) => {
          if (product.errors.length > 0) {
              branch.can_upload = false
          }
        }); 

        // console.log(branch.can_upload);
      });

      
    };

    const getFile = computed(() => {

      return branches.find((branch) => {
        if ( branch.id == current_branch.value) {
          console.log("get file")
          console.log(branch);
          return branch;
        }
      }).file
    })

    const RequestStore = async (storeId,ext_id) => {
      let data = JSON.stringify({
        query: `mutation UpdateStorage($storageId: ID!, $ext_id: String) {
          UpdateStorage(input: {
              id: $storageId
              ext_id: $ext_id
          }) {
              id,
              ext_id
          }
      }`,
        variables: {"storageId":storeId,"ext_id":ext_id}
      });

      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `https://${baseAdres.value}/graphql`,
        headers: { 
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer ' + token.value,
          // 'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjoiZTI5YWQ1ZTMtN2E0Ni00Mjg0LWJhYTktNDQxMWZlNGVkNjRlIiwiZW52IjoicHJvZCIsImlhdCI6MTY4NDE1ODA0NSwiZXhwIjoxNjg1OTU4MDQ1LCJzdWIiOiJlMjlhZDVlMy03YTQ2LTQyODQtYmFhOS00NDExZmU0ZWQ2NGUifQ.LPLKcb_mdmL-Mqbvl9PSOUimTzFGeP_JYnNqdzqzhYE'
        },
        data : data
      };

      let response = await axios.request(config)

      console.log(response);
    }

    const ChengeFile = (file) => {
      branches.find((branch) => {
        if ( branch.id == current_branch.value ) {
          // console.log(branch);
          return branch;
        }
      }).file = file;
    }

    const chengeBranch = (products, err_count) => {

      branches.find((branch) => {
         if ( branch.id == current_branch.value ) return branch
      }).products = products;

      branches.find((branch) => { if ( branch.id == current_branch.value ) return branch}).errors_count=err_count;
      // console.log(...branches);
      // chengeIsLogin();

    }

    const checkIsLoaded = async (id) => {
      console.log(id)
      let data = JSON.stringify({
        query: `query checiIsLoad{
          CurrentRemainders(input:{branch:"${id}",pagination:{skip:0,take:1000}}){
            remainders{
              product{name}
            }
            total
          }
        }`,
      });

      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `https://${baseAdres.value}/graphql`,
        headers: { 
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer ' + token.value,
          // 'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjoiZTI5YWQ1ZTMtN2E0Ni00Mjg0LWJhYTktNDQxMWZlNGVkNjRlIiwiZW52IjoicHJvZCIsImlhdCI6MTY4NDE1ODA0NSwiZXhwIjoxNjg1OTU4MDQ1LCJzdWIiOiJlMjlhZDVlMy03YTQ2LTQyODQtYmFhOS00NDExZmU0ZWQ2NGUifQ.LPLKcb_mdmL-Mqbvl9PSOUimTzFGeP_JYnNqdzqzhYE'
        },
        data : data
      };

      const resp = await axios.request(config);
      console.log(resp)
      if (resp?.data?.data?.CurrentRemainders?.total > 0) return true;
      return false; 
    }
  
    const parseToken = () => {
      return JSON.parse(Buffer.from( token.value.split('.')[1], 'base64').toString())
    }

    return{
      parseToken,
      checkIsLoaded,
      IsBetaAdres,
      chengeBranch,  
      getFile,
      count,
      token,
      branches,
      current_branch,
      isLogin,
      getBranchProducts,
      RequestLogin,
      RequestBranch,
      changeCanUpload,
      getBranch,
      navIndex,
      chengeNavIndex,
      RequestErrors,
      ChengeFile,
      getCurrentBranch,
      chengeBaseAdres,
      error_filter,
      baseAdres,
      RequestSaveBase,
      RequestCompaundSaveBase,
      RequestCompaundErrors,
      GetBranchErrorsCount,
      GetBranchFilteredErrorsCount,
      isError,
      RequestFixFile,
      UploadFileToBack,
      RequestStore,
      set_double_barcode,
      set_double_plu,
      set_bad_remain,
      change_bad_remainder,
      current_ychetka,
    };
})