import { defineStore } from 'pinia'
import { ref} from 'vue';
// import axios from "axios";
// import Buffer from 'buffer'

// import ref from '@ref'
export const useGlobalErrorStore = defineStore('globalErrorStore', () => {

    const errors = ref([]);
   
    
    const GetError = async (error) => {
        
        errors.value.push(error);
        await setTimeout(() => {},40000);
        console.log(errors.value)
        errors.value = errors.value.filter((er) => {return er!=error} )
        
    }
  
    return{
        GetError
    };
})